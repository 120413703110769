import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
} from "@chakra-ui/react";
import { useRef } from "react";

interface Props {
    isOpen: boolean;
    title: string;
    body: string;
    confirmLabel: string;
    onClose: () => void;
    onConfirm: () => void;
}

const ConfirmationDialog = ({ isOpen, title, onClose, confirmLabel, body, onConfirm }: Props) => {
    const cancelRef = useRef(null);

    return (
        <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelRef}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        {title}
                    </AlertDialogHeader>

                    <AlertDialogBody>{body}</AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            Avbryt
                        </Button>
                        <Button colorScheme="red" onClick={onConfirm} ml={3}>
                            {confirmLabel}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};

export default ConfirmationDialog;
