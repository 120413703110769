import { Heading, Stack, Text } from "@chakra-ui/react";

interface Props {
    text?: string;
}

const NoContent = ({ text }: Props) => {
    return (
        <Stack alignItems={"center"}>
            <Text fontSize={"9xl"}>🫥</Text>
            <Heading fontSize={"2xl"}>Her var det vindstille</Heading>
            <Text>{text ? text : "Siden mangler innhold"}</Text>
        </Stack>
    );
};

export default NoContent;
