import { useEffect, useState } from "react";
import {
    Box,
    Button,
    Card,
    CardBody,
    CardFooter,
    Heading,
    SimpleGrid,
    Text,
    VStack,
    Image,
    HStack,
    Stack,
} from "@chakra-ui/react";
import supabase from "../../config/supabaseClient";
import { NewsRow } from "../../types";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useContext } from "react";
import { AuthContext } from "../../App";
import logo from "../../assets/logo.png";
import { ArchiveIcon } from "../../components/CustomIcons";
import NoContent from "../../components/NoContent";

const News = () => {
    const [news, setNews] = useState<NewsRow[]>();
    const [fetchError, setFetchError] = useState<string>();
    const { isAdmin } = useContext(AuthContext);
    const [showArchived, setShowArchived] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchNews = async () => {
            const { data, error } = await supabase
                .from("news")
                .select()
                .eq("archived", showArchived)
                .order("last_modified", { ascending: false });

            if (error) {
                setFetchError("Kunne ikke hente nyheter");
                setNews(undefined);
                return;
            }

            setFetchError("");
            setNews(data);
        };

        fetchNews();
    }, [showArchived]);

    if (!news) {
        return <Text>{fetchError}</Text>;
    }

    const PageToolbar = () => {
        return (
            <HStack justifyContent={"space-between"} mb={6}>
                <Heading>{showArchived ? "Arkiverte nyheter" : "Nyheter"}</Heading>
                <HStack spacing={2}>
                    <Button
                        pl={showArchived ? "8px" : "16px"}
                        leftIcon={showArchived ? <></> : <ArchiveIcon />}
                        onClick={() => setShowArchived(!showArchived)}
                    >
                        {showArchived ? "Nyheter" : "Arkiv"}
                    </Button>
                    {isAdmin && <Button onClick={() => navigate("/nyheter/opprett")}>Opprett nyhet</Button>}
                </HStack>
            </HStack>
        );
    };

    if (news.length === 0) {
        return (
            <Stack>
                <PageToolbar />
                <NoContent text={"Ingen nyheter"} />
            </Stack>
        );
    }

    return (
        <Box>
            <PageToolbar />
            <SimpleGrid columns={{ sm: 2, md: 3 }} spacing={4}>
                {news.map((article) => (
                    <Card key={article.id} variant="outline" borderColor={"gray.400"}>
                        <CardBody>
                            <Image
                                src={`https://dxkczmnvoadzwabfzont.supabase.co/storage/v1/object/public/images/news/${article.id}`}
                                fallbackSrc={logo}
                                alt=""
                                borderRadius="lg"
                                h={"180px"}
                                objectFit="cover"
                                width={"100%"}
                            />
                            <VStack mt={4}>
                                <Heading size={"md"}>{article.title}</Heading>
                                <Text>{article.short_description}</Text>
                            </VStack>
                        </CardBody>
                        <CardFooter>
                            <VStack alignItems={"start"}>
                                <Text color={"gray"} mt={1}>{`Opprettet: ${moment(article.created_at).format(
                                    "HH:mm D. MMM YYYY"
                                )}`}</Text>
                                <Button onClick={() => navigate(`/nyheter/artikkel/${article.id}`)}>Les mer</Button>
                            </VStack>
                        </CardFooter>
                    </Card>
                ))}
            </SimpleGrid>
        </Box>
    );
};

export default News;
