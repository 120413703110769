import {
    Button,
    Card,
    CardBody,
    CardFooter,
    Divider,
    Heading,
    HStack,
    IconButton,
    Image,
    Stack,
    Text,
} from "@chakra-ui/react";
import { ProductRow } from "../../../types";
import logo from "../../../assets/logo.png";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";

interface Props {
    product: ProductRow;
    onEdit?: (id: number) => void;
    onDelete?: (id: number) => void;
}

const ProductCard = ({ product, onDelete, onEdit }: Props) => {
    const handleBuy = () => {
        const link = `mailto:sekretaer@havseilerklubben.no?subject=Bestilling%20av%20klubbeffekter&body=Hei%2C%0D%0A%0D%0AJeg%20%C3%B8nsker%20%C3%A5%20bestille%20${product.name.replace(
            " ",
            "%20"
        )}%20fra%20NHK's%20nettbutikk.%0D%0A%0D%0AMed%20vennlig%20hilsen%2C%0D%0ADITT%20NAVN%20HER`;

        window.location.href = link;
    };

    return (
        <Card maxW="sm">
            <CardBody>
                <Image
                    src={`https://dxkczmnvoadzwabfzont.supabase.co/storage/v1/object/public/images/store/${product.id}`}
                    fallbackSrc={logo}
                    alt={product.name}
                    borderRadius="lg"
                    h={"180px"}
                    objectFit="cover"
                    width={"100%"}
                />
                <Stack mt="6" spacing="3">
                    <Heading size="md">{product.name}</Heading>
                    <Text>{product.description}</Text>
                    <Text color="blue.600" fontSize="2xl">
                        {product.price}kr
                    </Text>
                </Stack>
            </CardBody>
            <Divider color={"gray"} />
            <CardFooter justifyContent={"space-between"}>
                <Button variant="solid" isDisabled={!product.in_stock} colorScheme="blue" onClick={handleBuy}>
                    {product.in_stock ? "Kjøp" : "Ikke på lager"}
                </Button>
                <HStack spacing={2}>
                    {onEdit && (
                        <IconButton onClick={() => onEdit(product.id)} icon={<EditIcon />} aria-label="Edit product" />
                    )}
                    {onDelete && (
                        <IconButton
                            onClick={() => onDelete(product.id)}
                            icon={<DeleteIcon />}
                            aria-label="Edit product"
                        />
                    )}
                </HStack>
            </CardFooter>
        </Card>
    );
};

export default ProductCard;
