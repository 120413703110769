import { Box, Stack, Text, Image } from "@chakra-ui/react";
import sailboat from "../../assets/sailboat.svg";

const About = () => {
    return (
        <Stack spacing={4}>
            <Box maxW={"80%"}>
                <Text
                    fontSize={{ base: "2xl", md: "3xl" }}
                    color={"#0a3391"}
                    alignSelf="start"
                    letterSpacing={"0.3rem"}
                    fontWeight="semibold"
                >
                    NORSK HAVSEILER- OG KRYSSERKLUBB
                </Text>
                <Box color={"#0a3391"} w="700px" maxW="100%" h={"2px"} bg={"#0a3391"} alignSelf="start" />
                <Text fontSize={"xl"} color={"#0a3391"} alignSelf="start" letterSpacing={"0.1rem"}>
                    NORWEGIAN OCEAN RACING AND CRUISING CLUB
                </Text>
            </Box>
            <Text>
                Norsk Havseiler - og Krysserklubb ble stiftet i 1953. Klubben arbeider for fremme av tur- og kappseilas
                over lengre distanser. NHK arrangerer kappseilaser i samarbeide med andre innen- og utenlandske
                seilforeninger, mest kjent er Hollænderseilasen som er norges største regatta etter Færderseilasen.
                NHK's medlemmer disponerer bøyer og båtplasser i havner langs norskekysten. NHK arrangerer temakvelder
                med aktuelle problemstillinger innenfor regatta -og langturseiling. NHK har en egen langtursbank med
                erfaringsmateriale fra alle verdensdeler. NHK's medlemmer representerer noen av de mest erfarne havkapp
                og langturseilere både i Norge og internasjonalt.
            </Text>
            <Image src={sailboat} h="100px" />
            <Text>
                Norwegian Ocean Racing and Cruising Club was established in 1953. The club is working to promote touring
                and racing regattas over long distances. NHK organizes regattas in cooperation with other domestic and
                foreign sailing clubs, most notably Hollænderseilasen, which is the largest regatta after the Færder
                Regatta. NHK´s members have access to buoys and ports along the Norwegian coast. NHK hosts themed
                evenings with current issues in race and long distance sailing. NHK has it's own long distance journey
                register with material and information from all continents. NHK's members represent some of the most
                experienced off-shore and long distance sailors, both in Norway and internationally.
            </Text>
        </Stack>
    );
};

export default About;
