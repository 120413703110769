import { Heading, Image, Stack } from "@chakra-ui/react";
import wave from "../../assets/404.svg";

const NotFound = () => {
    return (
        <Stack alignItems={"center"} mt={"50px"} spacing="50px">
            <Image src={wave} alt={"404"} height={"200px"} borderRadius={"100%"} border={"2px solid lightblue"} />
            <Heading>Siden du leter etter ble desverre borte med strømmen</Heading>
        </Stack>
    );
};
export default NotFound;
