import {
    Box,
    Button,
    Card,
    CardBody,
    CardFooter,
    Heading,
    Image,
    Stack,
    useToast,
    HStack,
    Checkbox,
    Text,
} from "@chakra-ui/react";
import { ChangeEvent, useEffect, useState } from "react";
import InputWithLabel, {
    TextareaWithLabel,
    MarkdownEditorWithLabel,
    FileInputWithLabel,
} from "../../components/InputWithLabel";
import supabase from "../../config/supabaseClient";
import { NewsInsert } from "../../types";
import { useNavigate, useParams } from "react-router-dom";
import { ArchiveIcon } from "../../components/CustomIcons";

const CreateArticle = () => {
    const [newsData, setNewsData] = useState<NewsInsert>({});
    const [image, setImage] = useState<File>();
    const [articleHasImage, setArticleHasImage] = useState(false);
    const toast = useToast();
    const navigate = useNavigate();

    // If updating an article, fetch the article data
    const { id } = useParams();
    useEffect(() => {
        if (!id) return;
        const fetchArticle = async () => {
            const { data, error } = await supabase.from("news").select().eq("id", id).single();
            if (error) {
                toast({
                    title: "Kunne ikke finne nyheten",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
                navigate("/nyheter");
            }
            if (data) {
                setNewsData(data);
            }
        };
        fetchArticle();
    }, [id, navigate, toast]);

    const handleChangeNewsData = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setNewsData({ ...newsData, [(e.target as HTMLInputElement).name]: (e.target as HTMLInputElement).value });
    };

    const handleSubmit = async (toggleArchive: boolean = false) => {
        const article = {
            ...newsData,
            last_modified: new Date().toISOString(),
            archived: toggleArchive ? !newsData.archived : newsData.archived,
        };
        const { data, error } = await supabase.from("news").upsert(article).select().single();

        if (error) {
            toast({
                title: "Kunne ikke opprette nyhet",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        if (data) {
            if (image) {
                const { error: imageError } = await supabase.storage
                    .from("images")
                    .upload("news/" + data.id, image, { cacheControl: "0", upsert: true });

                if (imageError) {
                    toast({ title: "Kunne ikke lagre bilde", status: "error", duration: 5000, isClosable: true });
                }
            }

            toast({
                title: id ? "Din nyhet ble oppdatert" : "Din nyhet ble opprettet",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            navigate(`/nyheter/artikkel/${data.id}`);
        }
    };

    return (
        <Box>
            <Heading mb={4}>{id ? "Oppdater nyhet" : "Opprett en nyhet"}</Heading>
            <Card variant={"outline"}>
                <CardBody>
                    <Stack spacing={4}>
                        <InputWithLabel
                            name={"title"}
                            label={"Overskrift"}
                            value={newsData.title ?? ""}
                            onChange={handleChangeNewsData}
                        />
                        <InputWithLabel
                            name={"short_description"}
                            label={"Kort beskrivelse"}
                            value={newsData.short_description ?? ""}
                            onChange={handleChangeNewsData}
                        />
                        {id && (
                            <>
                                {articleHasImage && <Text>Artikkelens bilde</Text>}
                                <Image
                                    height={"150px"}
                                    objectFit={"contain"}
                                    src={`https://dxkczmnvoadzwabfzont.supabase.co/storage/v1/object/public/images/news/${id}`}
                                    alt=""
                                    alignSelf={"start"}
                                    onLoad={() => setArticleHasImage(true)}
                                    display={articleHasImage ? "block" : "none"}
                                />
                            </>
                        )}
                        <FileInputWithLabel
                            label={articleHasImage ? "" : "Artikkelens bilde"}
                            buttonLabel={articleHasImage ? "Erstatt bilde" : "Last opp bilde"}
                            value={image}
                            onChange={(e) => setImage(e)}
                            accept={"image/*"}
                        />
                        <Checkbox
                            name="rich_text"
                            isChecked={newsData.rich_text}
                            onChange={(e) => setNewsData({ ...newsData, rich_text: e.target.checked })}
                        >
                            Bruk rik tekst
                        </Checkbox>
                        {newsData.rich_text ? (
                            <MarkdownEditorWithLabel
                                label={"Innhold"}
                                value={newsData.text ?? ""}
                                onChange={(value) => setNewsData({ ...newsData, text: value })}
                            />
                        ) : (
                            <TextareaWithLabel
                                name={"text"}
                                label={"Innhold"}
                                value={newsData.text ?? ""}
                                onChange={handleChangeNewsData}
                            />
                        )}
                    </Stack>
                </CardBody>
                <CardFooter>
                    <HStack spacing={2}>
                        <Button onClick={() => handleSubmit()}>{id ? "Oppdater nyhet" : "Opprett nyhet"}</Button>
                        {id && (
                            <Button
                                onClick={() => {
                                    handleSubmit(true);
                                }}
                                leftIcon={<ArchiveIcon />}
                            >
                                {newsData.archived ? "Opphev arkivering" : "Arkiver nyhet"}
                            </Button>
                        )}
                    </HStack>
                </CardFooter>
            </Card>
        </Box>
    );
};

export default CreateArticle;
