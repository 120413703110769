import { useEffect, useState } from "react";
import { Card, CardBody, Heading, Stack, Text, Image } from "@chakra-ui/react";
import supabase from "../../config/supabaseClient";
import { useNavigate } from "react-router-dom";
import { getNorwegianName } from "./utils";
import folderImage from "./assets/folder.png";

const Gallery = () => {
    const [folders, setFolders] = useState<string[]>([]);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchImages = async () => {
            const { data } = await supabase.storage.from("images").list("gallery", {
                limit: 100,
                offset: 0,
                sortBy: { column: "name", order: "desc" },
            });
            if (data) {
                setFolders(data.map((folder) => folder.name));
            }
        };
        fetchImages();
    }, []);

    return (
        <Stack>
            <Heading>Bildegalleri</Heading>
            {folders.map((folderName) => (
                <Card
                    variant={"outline"}
                    direction="row"
                    overflow={"hidden"}
                    borderColor={"gray.400"}
                    width={"100%"}
                    alignItems={"center"}
                    onClick={() => navigate(`/bildearkiv/${folderName}`)}
                    px={4}
                    cursor="pointer"
                >
                    <Image src={folderImage} borderRadius="lg" objectFit="cover" maxW={"32px"} />
                    <CardBody>
                        <Text>{getNorwegianName(folderName)}</Text>
                    </CardBody>
                </Card>
            ))}
        </Stack>
    );
};

export default Gallery;
