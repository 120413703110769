import { Box, Button, Heading, HStack, Stack, Text, VStack } from "@chakra-ui/react";
import { PhoneIcon, EmailIcon, AtSignIcon } from "@chakra-ui/icons";
import wave from "../assets/wave.svg";
import { useNavigate } from "react-router-dom";

const Footer = () => {
    const navigate = useNavigate();

    return (
        <footer>
            <HStack backgroundImage={wave} backgroundSize={"cover"} justifyContent={"center"}>
                <Box w="7xl" maxW="100%" px={4} pt={{ base: "150px", md: "100px" }} color="white">
                    <Stack direction={{ base: "column", sm: "column", md: "row" }} spacing={14}>
                        <VStack alignItems={"start"}>
                            <Heading size={"md"}>NHK</Heading>
                            <Text>Norsk Havseiler- og Krysserklubb</Text>
                            <Text>
                                <AtSignIcon mr={1} />
                                Halvorsens vei 22B, 1365 Blommenholm
                            </Text>
                        </VStack>
                        <VStack alignItems={"start"}>
                            <Heading size={"md"}>Kontakt oss</Heading>
                            <Text>
                                <PhoneIcon mr={1} />
                                959 24 007
                            </Text>
                            <Text>
                                <EmailIcon mr={1} />
                                sekretaer@havseilerklubben.no
                            </Text>
                        </VStack>
                    </Stack>
                    <Button color={"black"} mt={"50px"} mb={2} onClick={() => navigate("/rapporter-problem")}>
                        Gi tilbakemelding
                    </Button>
                    <Text fontSize="xs" color={"white"} mb={"40px"}>
                        Utviklet av Nikolai Dokken
                    </Text>
                </Box>
            </HStack>
        </footer>
    );
};

export default Footer;
