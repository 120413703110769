import { useEffect, useState } from "react";
import { Card, CardBody, Heading, Stack, Text, Image } from "@chakra-ui/react";
import supabase from "../../config/supabaseClient";
import { FileObject } from "@supabase/storage-js";
import thumbnail from "./assets/document.png";

const CDN_URL = `https://dxkczmnvoadzwabfzont.supabase.co/storage/v1/object/public/files/abstracts/`;

const Abstracts = () => {
    const [abstracts, setAbstracts] = useState<FileObject[]>([]);

    useEffect(() => {
        const fetchAbstracts = async () => {
            const { data } = await supabase.storage.from("files").list("abstracts", {
                limit: 100,
                offset: 0,
                sortBy: { column: "name", order: "desc" },
            });
            if (data) {
                setAbstracts(data);
            }
        };
        fetchAbstracts();
    }, []);

    return (
        <Stack>
            <Heading>Generalforsamlings-protokoller</Heading>
            {abstracts.map((abstract) => (
                <a href={CDN_URL + abstract.name} key={abstract.name}>
                    <Card
                        variant={"outline"}
                        direction="row"
                        overflow={"hidden"}
                        borderColor={"gray.400"}
                        width={"100%"}
                        alignItems={"center"}
                        paddingX={2}
                    >
                        <Image src={thumbnail} borderRadius="lg" objectFit="cover" maxW={"32px"} />
                        <CardBody>
                            <Text>{abstract.name}</Text>
                        </CardBody>
                    </Card>
                </a>
            ))}
        </Stack>
    );
};

export default Abstracts;
