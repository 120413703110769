import { useEffect, useRef, useState } from "react";
import {
    Box,
    Button,
    Card,
    CardBody,
    CardFooter,
    Heading,
    SimpleGrid,
    Text,
    VStack,
    HStack,
    IconButton,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";
import supabase from "../../config/supabaseClient";
import { NoticeRow } from "../../types";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useContext } from "react";
import { AuthContext } from "../../App";
import { AtSignIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import ConfirmationDialog from "../../components/ConfirmationDialog";

const Notices = () => {
    const [notices, setNotices] = useState<NoticeRow[]>([]);
    const [fetchError, setFetchError] = useState<string>();
    const { user, isAdmin } = useContext(AuthContext);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const clickedNotice = useRef<number>();
    const toast = useToast();

    const navigate = useNavigate();

    useEffect(() => {
        const fetchNotices = async () => {
            const { data, error } = await supabase
                .from("notices")
                .select()
                .or(`expires.gte.${moment().format("YYYY-MM-DD")},expires.is.null`)
                .order("created_at", { ascending: false });

            if (error) {
                setFetchError("Kunne ikke hente oppslag");
                setNotices([]);
                return;
            }

            setFetchError("");
            setNotices(data);
        };

        fetchNotices();
    }, []);

    const handleDelete = async () => {
        if (!clickedNotice.current || notices.length === 0) {
            return;
        }

        const { error } = await supabase.from("notices").delete().eq("id", clickedNotice.current);

        if (error) {
            toast({
                title: "Kunne ikke slette oppslag",
                description: "Prøv igjen senere",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }
        setNotices(notices.filter((notice) => notice.id !== clickedNotice.current));

        toast({
            title: "Oppslaget ble slettet",
            status: "success",
            duration: 5000,
            isClosable: true,
        });

        clickedNotice.current = undefined;
        onClose();
    };

    const HeaderRow = () => (
        <HStack justifyContent={"space-between"} mb={6}>
            <Heading>Oppslagstavle</Heading>
            <HStack spacing={2}>
                <Button onClick={() => navigate("/oppslagstavle/opprett")}>Opprett oppslag</Button>
            </HStack>
        </HStack>
    );

    if (fetchError) {
        return <Text>{fetchError}</Text>;
    }

    if (notices.length === 0) {
        return (
            <Box>
                <HeaderRow />
                <Text>Det er ingen oppslag å vise</Text>
            </Box>
        );
    }

    return (
        <Box>
            <HeaderRow />
            <SimpleGrid columns={{ sm: 2, md: 3 }} spacing={4}>
                {notices.map((notice) => (
                    <Card key={notice.id} variant="elevated" backgroundColor={"#FFFF99"} border={"1px solid lightgray"}>
                        <CardBody>
                            <VStack alignItems={"start"}>
                                <HStack>
                                    <AtSignIcon />
                                    <Text fontWeight={"bold"}>{notice.author}</Text>
                                </HStack>
                                <Heading size={"md"}>{notice.title}</Heading>
                                <Text>{notice.text}</Text>
                            </VStack>
                        </CardBody>
                        <CardFooter>
                            <HStack justifyContent={"space-between"} width={"100%"} mb={0}>
                                <Text color={"gray"}>{`Opprettet: ${moment(notice.created_at).format(
                                    "HH:mm D. MMM YYYY"
                                )}`}</Text>
                                {(notice.user_uid === user?.id || isAdmin) && (
                                    <HStack spacing={2}>
                                        <IconButton
                                            variant="outline"
                                            aria-label="Delete"
                                            fontSize="20px"
                                            borderColor={"black"}
                                            icon={<EditIcon />}
                                            onClick={() => navigate(`/oppslagstavle/opprett/${notice.id}`)}
                                        />
                                        <IconButton
                                            variant="outline"
                                            aria-label="Delete"
                                            fontSize="20px"
                                            borderColor={"black"}
                                            icon={<DeleteIcon />}
                                            onClick={() => {
                                                clickedNotice.current = notice.id;
                                                onOpen();
                                            }}
                                        />
                                    </HStack>
                                )}
                            </HStack>
                        </CardFooter>
                    </Card>
                ))}
            </SimpleGrid>
            <ConfirmationDialog
                title={"Slett oppslag"}
                body={"Er du sikker på at du vil slette oppslaget?"}
                confirmLabel={"Ja, slett oppslaget"}
                isOpen={isOpen}
                onClose={onClose}
                onConfirm={handleDelete}
            />
        </Box>
    );
};

export default Notices;
