export enum ProblemStatus {
    Open = "OPEN",
    Closed = "CLOSED",
    Resolved = "RESOLVED",
}

export const ProblemStatusColor = {
    [ProblemStatus.Open]: "blue",
    [ProblemStatus.Closed]: "pink",
    [ProblemStatus.Resolved]: "green",
};

export enum ProblemType {
    Bug = "BUG",
    Feature = "FEATURE",
    Enhancement = "ENHANCEMENT",
}

export const ProblemTypeColor = {
    [ProblemType.Bug]: "yellow",
    [ProblemType.Feature]: "blue",
    [ProblemType.Enhancement]: "green",
};
