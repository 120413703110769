import { Box, Button, Card, CardBody, CardFooter, CardHeader, SimpleGrid, Text, useToast } from "@chakra-ui/react";
import moment from "moment";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { Link, useBlocker } from "react-router-dom";
import { AuthContext } from "../App";
import ConfirmationDialog from "../components/ConfirmationDialog";
import InputWithLabel from "../components/InputWithLabel";
import supabase from "../config/supabaseClient";
import { MemberUpdate } from "../types";

const Profile = () => {
    const [memberInfo, setMemberInfo] = useState<MemberUpdate>({});
    const [oldMemberInfo, setOldMemberInfo] = useState<MemberUpdate>({});
    const [changedInfo, setChangedInfo] = useState(false);
    const { user } = useContext(AuthContext);
    const toast = useToast();

    // Block navigating elsewhere when data has been entered into the input
    let blocker = useBlocker(
        ({ currentLocation, nextLocation }) =>
            memberInfo !== oldMemberInfo && currentLocation.pathname !== nextLocation.pathname
    );

    useEffect(() => {
        if (!user) return;

        const fetchMember = async () => {
            const { data, error } = await supabase.from("members").select().eq("id", user.id).single();

            if (error) {
                toast({
                    title: "Vennligst oppdater din informasjon",
                    status: "warning",
                    duration: 5000,
                    isClosable: true,
                });
            }

            if (data) {
                setMemberInfo(data);
                setOldMemberInfo(data);
            }
        };

        fetchMember();
    }, [user, toast]);

    const handleChangeMemberInfo = (e: ChangeEvent<HTMLInputElement>) => {
        if (!changedInfo) setChangedInfo(true);
        setMemberInfo({ ...memberInfo, [(e.target as HTMLInputElement).name]: (e.target as HTMLInputElement).value });
    };

    const handleSubmit = async () => {
        if (!user) return;

        const updatedMemberInfo = {
            ...memberInfo,
            id: user.id,
            build_year: memberInfo.build_year || 0,
        };

        const { error } = await supabase.from("members").upsert(updatedMemberInfo).eq("id", user.id);

        if (error) {
            toast({
                title: "Kunne ikke oppdatere info",
                description: error.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } else {
            toast({
                title: "Din info ble oppdatert",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            setChangedInfo(false);
            setMemberInfo(updatedMemberInfo);
            setOldMemberInfo(updatedMemberInfo);
        }
    };

    if (!user) {
        return <Text>Vennlist logg inn</Text>;
    }

    return (
        <Box>
            <Card variant={"outline"}>
                <CardHeader fontWeight={"bold"}>Kontoinformasjon</CardHeader>
                <CardBody py={0}>
                    <SimpleGrid columns={{ lg: 3 }} spacing={1}>
                        <Text color={"gray"}>{user?.email}</Text>
                        <Text color={"gray"}>{`Sist innlogget ${moment(user.last_sign_in_at).format(
                            "D. MMM YYYY HH:mm"
                        )}`}</Text>
                        <Text color={"gray"}>{`Bruker opprettet den ${moment(user.created_at).format(
                            "D. MMM YYYY HH:mm"
                        )}`}</Text>
                        <Link to="/glemtpassord">
                            <Button maxW={"150px"}>Endre passord</Button>
                        </Link>
                    </SimpleGrid>
                </CardBody>
                <CardHeader fontWeight="bold">Medlemsinformasjon</CardHeader>
                <CardBody pt={0}>
                    <SimpleGrid columns={{ md: 2 }} spacing={4}>
                        <InputWithLabel
                            name={"full_name"}
                            label={"Fullt navn"}
                            placeholder="Ola Nordmann"
                            value={memberInfo.full_name || ""}
                            onChange={handleChangeMemberInfo}
                        />
                        <InputWithLabel
                            name={"email"}
                            label={"Kontakt epost"}
                            placeholder="ola@nordmann.no"
                            value={memberInfo.email || ""}
                            onChange={handleChangeMemberInfo}
                        />
                        <InputWithLabel
                            name={"phone"}
                            label={"Telefon"}
                            placeholder="+47 12345678"
                            value={memberInfo.phone || ""}
                            onChange={handleChangeMemberInfo}
                        />
                        <InputWithLabel
                            name={"address"}
                            label={"Adresse"}
                            placeholder="Hovedveien 31B"
                            value={memberInfo.address || ""}
                            onChange={handleChangeMemberInfo}
                        />
                        <InputWithLabel
                            name={"zip_code"}
                            label={"Postnr"}
                            placeholder="1365"
                            value={memberInfo.zip_code || ""}
                            onChange={handleChangeMemberInfo}
                        />
                        <InputWithLabel
                            name={"city"}
                            label={"Poststed"}
                            placeholder="Blommenholm"
                            value={memberInfo.city || ""}
                            onChange={handleChangeMemberInfo}
                        />
                        <InputWithLabel
                            name={"country"}
                            label={"Land"}
                            placeholder="Norge"
                            value={memberInfo.country || ""}
                            onChange={handleChangeMemberInfo}
                        />
                    </SimpleGrid>
                </CardBody>
                <CardHeader fontWeight="bold">Båt-informasjon</CardHeader>
                <CardBody pt={0}>
                    <SimpleGrid columns={{ md: 2 }} spacing={4}>
                        <InputWithLabel
                            name={"boat_type"}
                            label={"Båttype"}
                            placeholder=""
                            value={memberInfo.boat_type || ""}
                            onChange={handleChangeMemberInfo}
                        />
                        <InputWithLabel
                            name={"boat_name"}
                            label={"Båtnavn"}
                            placeholder="Kon Tiki"
                            value={memberInfo.boat_name || ""}
                            onChange={handleChangeMemberInfo}
                        />
                        <InputWithLabel
                            name={"build_year"}
                            label={"Byggeår"}
                            placeholder="1990"
                            value={memberInfo.build_year || ""}
                            onChange={handleChangeMemberInfo}
                        />
                        <InputWithLabel
                            name={"sail_regnr"}
                            label={"Seilnr"}
                            placeholder="NOR-00000"
                            value={memberInfo.sail_regnr || ""}
                            onChange={handleChangeMemberInfo}
                        />
                        <InputWithLabel
                            name={"vhf_nr"}
                            label={"VHF nr"}
                            placeholder="LL-0000"
                            value={memberInfo.vhf_nr || ""}
                            onChange={handleChangeMemberInfo}
                        />
                        <InputWithLabel
                            name={"mmsi_nr"}
                            label={"MSSI nr"}
                            placeholder="250000000"
                            value={memberInfo.mmsi_nr || ""}
                            onChange={handleChangeMemberInfo}
                        />
                        <InputWithLabel
                            name={"designer"}
                            label={"Konstruktør"}
                            placeholder=""
                            value={memberInfo.designer || ""}
                            onChange={handleChangeMemberInfo}
                        />
                    </SimpleGrid>
                </CardBody>
                <CardFooter>
                    <Button isDisabled={!changedInfo} onClick={handleSubmit}>
                        Lagre endringer
                    </Button>
                </CardFooter>
            </Card>
            <ConfirmationDialog
                isOpen={blocker.state === "blocked"}
                title={"Du har endringer som ikke er lagret"}
                body={"Husk å lagre endringene dine nederst på siden!"}
                confirmLabel={"Forlat siden uten å lagre"}
                onConfirm={() => blocker && blocker.proceed && blocker.proceed()}
                onClose={() => blocker && blocker.reset && blocker.reset()}
            />
        </Box>
    );
};

export default Profile;
