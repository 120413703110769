import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    VStack,
    InputGroup,
    InputLeftElement,
    Input,
    Text,
    HStack,
    Heading,
    useToast,
} from "@chakra-ui/react";
import { ChangeEvent, useState, useContext, useEffect } from "react";
import { LockIcon, EmailIcon } from "@chakra-ui/icons";
import supabase from "../../config/supabaseClient";
import { AuthContext } from "../../App";
import { getURL } from "../../config/utils";
import { useNavigate } from "react-router-dom";

interface Props {
    newUser?: boolean;
}

const ResetPassword = ({ newUser = false }: Props) => {
    const { event, user } = useContext(AuthContext);
    const [credentials, setCredentials] = useState({
        email: "",
        newPassword: "",
        newPasswordVerify: "",
    });
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const toast = useToast();
    const navigate = useNavigate();

    const handleChangeCredentials = (e: ChangeEvent<HTMLInputElement>) => {
        setCredentials({ ...credentials, [(e.target as HTMLInputElement).name]: (e.target as HTMLInputElement).value });
    };

    useEffect(() => {
        if (user && user.email && credentials.email !== user.email) {
            setCredentials({ ...credentials, email: user.email });
        }
    }, [user, credentials]);

    const handleResetPassword = async () => {
        let recoveryFunction = null;
        if (isReadyForNewPassword()) {
            recoveryFunction = supabase.auth.updateUser({ password: credentials.newPassword });
            navigate("/profil");
        } else {
            setHasSubmitted(true);
            recoveryFunction = supabase.auth.resetPasswordForEmail(credentials.email, {
                redirectTo: `${getURL()}glemtpassord`,
            });
        }

        const { data, error } = await recoveryFunction;

        if (error) {
            toast({
                title: "En feil oppsto",
                description: error.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        if (data) {
            toast({
                title: isReadyForNewPassword() ? "Ditt passord er oppdatert" : "En epost har blitt sendt til deg",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            setTimeout(() => {
                setHasSubmitted(false);
            }, 5000);
        }
    };

    const isReadyForNewPassword = () => event === "PASSWORD_RECOVERY" || !!user;

    return (
        <HStack justifyContent={"center"} my={"100px"}>
            <Card w={"xl"} maxW={"100%"}>
                <CardHeader textAlign={"center"}>
                    <Heading fontWeight={"normal"}>{newUser ? "Velkommen!" : "Glemt passord"}</Heading>
                    {newUser && (
                        <>
                            <Text mt={2} fontWeight="bold">
                                Vennligst opprett et passord til din nye brukerkonto
                            </Text>
                            <Text mt={2}>På neste side vil vi be deg fylle ut og lagre medlemsinformasjon</Text>
                        </>
                    )}
                </CardHeader>
                <CardBody>
                    {isReadyForNewPassword() ? (
                        <VStack spacing={2}>
                            <InputGroup>
                                <InputLeftElement pointerEvents="none" children={<LockIcon color="gray" />} />
                                <Input
                                    name="newPassword"
                                    placeholder="Nytt passord"
                                    value={credentials.newPassword}
                                    onChange={handleChangeCredentials}
                                    type="password"
                                ></Input>
                            </InputGroup>
                            <InputGroup>
                                <InputLeftElement pointerEvents="none" children={<LockIcon color="gray" />} />
                                <Input
                                    name="newPasswordVerify"
                                    placeholder="Gjenta nytt passord"
                                    value={credentials.newPasswordVerify}
                                    onChange={handleChangeCredentials}
                                    type="password"
                                ></Input>
                            </InputGroup>
                            {credentials.newPassword &&
                                credentials.newPasswordVerify &&
                                credentials.newPassword !== credentials.newPasswordVerify && (
                                    <Text alignSelf={"start"} color={"red"}>
                                        Passord matcher ikke
                                    </Text>
                                )}
                        </VStack>
                    ) : (
                        <VStack>
                            <InputGroup>
                                <InputLeftElement pointerEvents="none" children={<EmailIcon color="gray" />} />
                                <Input
                                    name="email"
                                    placeholder="Epost"
                                    value={credentials.email}
                                    onChange={handleChangeCredentials}
                                ></Input>
                            </InputGroup>
                            <Text textAlign={"center"} color="gray">
                                En epost vil sendes til deg når du klikker på knappen under. Husk å sjekke søppelmappen
                                din om du ikke finner eposten i innboksen din.
                            </Text>
                        </VStack>
                    )}
                </CardBody>

                <CardFooter>
                    <VStack flex={1}>
                        {isReadyForNewPassword() ? (
                            <Button
                                w={"100%"}
                                colorScheme="blue"
                                onClick={handleResetPassword}
                                isDisabled={
                                    credentials.newPassword !== credentials.newPasswordVerify ||
                                    !credentials.newPassword
                                }
                            >
                                Oppdater passord
                            </Button>
                        ) : (
                            <Button
                                w={"100%"}
                                colorScheme="blue"
                                onClick={handleResetPassword}
                                isDisabled={hasSubmitted || !credentials.email}
                            >
                                Oppdater passord
                            </Button>
                        )}
                    </VStack>
                </CardFooter>
            </Card>
        </HStack>
    );
};

export default ResetPassword;
