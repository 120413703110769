import { Card, CardBody, Image, Heading, Stack, Text, CardFooter, Button } from "@chakra-ui/react";
import { PhoneIcon, EmailIcon } from "@chakra-ui/icons";
import { BoardMemberRow } from "../../../types";

interface Props {
    member: BoardMemberRow;
    handleEdit?: () => void;
    handleDelete?: () => void;
}

const BoardMember = ({ member, handleEdit, handleDelete }: Props) => {
    const CDN_URL = "https://dxkczmnvoadzwabfzont.supabase.co/storage/v1/object/public/images/board_members/";

    return (
        <Card direction={{ base: "column", sm: "row" }} variant="filled" background={"white"} p={2}>
            <Stack justifyContent={"center"} alignItems="center">
                <Image
                    src={CDN_URL + member.id}
                    fallbackSrc={CDN_URL + "fallback"}
                    borderRadius={"70% 30% 70% 30% / 24% 29% 71% 76% "}
                    w={"200px"}
                    h={"200px"}
                    objectFit={"cover"}
                />
            </Stack>

            <Stack>
                <CardBody>
                    <Heading size="md">{member.full_name}</Heading>

                    <Text pt="2" as={"u"}>
                        {member.role}
                    </Text>
                    <Text pt="2">
                        <EmailIcon mr={1} />
                        {member.email}
                    </Text>
                    <Text pt="2">
                        <PhoneIcon /> {member.phone}
                    </Text>
                </CardBody>
                {(handleDelete || handleEdit) && (
                    <CardFooter>
                        {handleEdit && (
                            <Button mr={2} onClick={handleEdit}>
                                Rediger info
                            </Button>
                        )}
                        {handleDelete && <Button onClick={handleDelete}>Slett</Button>}
                    </CardFooter>
                )}
            </Stack>
        </Card>
    );
};

export default BoardMember;
