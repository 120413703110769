import { Card, CardBody, Heading, Stack, Text } from "@chakra-ui/react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { EventRow } from "../../types";

interface Props {
    event: EventRow;
}

const EventCard = ({ event }: Props) => {
    const navigate = useNavigate();

    return (
        <Card
            key={event.id}
            variant="outline"
            direction={"row"}
            overflow="hidden"
            borderColor={"gray.400"}
            width={"100%"}
            onClick={() => navigate(`/aktiviteter/aktivitet/${event.id}`)}
            sx={{ cursor: "pointer" }}
            alignItems={"center"}
        >
            <Stack alignItems={"center"} p={2} spacing={0} ml={2}>
                <Text fontSize={"md"} fontWeight="bold">
                    {moment(event.date).format("ddd")}
                </Text>
                <Heading mt={0}>{moment(event.date).format("DD")}</Heading>
                <Text fontSize={"md"} fontWeight="bold">
                    {moment(event.date).format("MMM").toUpperCase()}
                </Text>
            </Stack>
            <CardBody>
                <Heading size={"md"}>{event.name}</Heading>
                <Text size={"md"}>{event.short_description}</Text>
            </CardBody>
        </Card>
    );
};

export default EventCard;
