import { Button, Card, CardBody, CardFooter, Heading, Image, Text, Stack } from "@chakra-ui/react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { NewsRow } from "../../../types";

import logo from "../../../assets/logo.png";

interface Props {
    article: NewsRow;
}

const RecentNewsRow = ({ article }: Props) => {
    const navigate = useNavigate();

    return (
        <Card
            key={article.id}
            variant="outline"
            borderColor={"gray.400"}
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            width={"100%"}
        >
            <Image
                src={`https://dxkczmnvoadzwabfzont.supabase.co/storage/v1/object/public/images/news/${article.id}`}
                fallbackSrc={logo}
                alt=""
                objectFit="cover"
                maxW={{ base: "100%", sm: "200px" }}
            />
            <Stack>
                <CardBody pb={0}>
                    <Heading size={"md"}>{article.title}</Heading>
                    <Text>{article.short_description}</Text>
                    <Text color={"gray"} mt={1}>{`Opprettet: ${moment(article.created_at).format(
                        "HH:mm D. MMM YYYY"
                    )}`}</Text>
                </CardBody>
                <CardFooter pt={0}>
                    <Button onClick={() => navigate(`/nyheter/artikkel/${article.id}`)}>Les mer</Button>
                </CardFooter>
            </Stack>
        </Card>
    );
};

export default RecentNewsRow;
