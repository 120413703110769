import {
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Badge,
    Box,
    Button,
    Card,
    HStack,
    Stack,
    Text,
    Image,
} from "@chakra-ui/react";
import { useState } from "react";
import { ProblemRow } from "../../../types";
import { ProblemStatus, ProblemStatusColor, ProblemType, ProblemTypeColor } from "../../../types/enums";

interface Props {
    problem: ProblemRow;
    updateProblemStatus: (id: number, status: ProblemStatus) => void;
}

const CDN_URL = "https://dxkczmnvoadzwabfzont.supabase.co/storage/v1/object/public/images_open/problems";

const ProblemCard = ({ problem, updateProblemStatus }: Props) => {
    const [problemHasImage, setProblemHasImage] = useState(false);

    return (
        <AccordionItem border={"none"} mb={2}>
            <Card variant={"outline"}>
                <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                        <Text fontWeight={"bold"}>{`#${problem.id}: ${problem.title}`}</Text>
                    </Box>
                    <Badge colorScheme={ProblemTypeColor[problem.type as ProblemType]} mr={2}>
                        {problem.type}
                    </Badge>
                    <Badge colorScheme={ProblemStatusColor[problem.status as ProblemStatus]}>{problem.status}</Badge>
                    <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                    <Stack>
                        <HStack alignItems={"start"}>
                            <Text flex={1}>{problem.description}</Text>
                            <a href={`${CDN_URL}/${problem.id}`}>
                                <Image
                                    height={"150px"}
                                    maxW={"200px"}
                                    objectFit={"contain"}
                                    src={`${CDN_URL}/${problem.id}`}
                                    alt=""
                                    alignSelf={"start"}
                                    onLoad={() => setProblemHasImage(true)}
                                    display={problemHasImage ? "block" : "none"}
                                />
                            </a>
                        </HStack>
                        <HStack justifyContent={"end"}>
                            {problem.status !== ProblemStatus.Resolved && (
                                <Button onClick={() => updateProblemStatus(problem.id, ProblemStatus.Resolved)}>
                                    Merk som løst
                                </Button>
                            )}
                            {problem.status !== ProblemStatus.Open && (
                                <Button onClick={() => updateProblemStatus(problem.id, ProblemStatus.Open)}>
                                    Gjenåpne problem
                                </Button>
                            )}
                            {problem.status === ProblemStatus.Open && (
                                <Button onClick={() => updateProblemStatus(problem.id, ProblemStatus.Closed)}>
                                    Lukk problem
                                </Button>
                            )}
                        </HStack>
                    </Stack>
                </AccordionPanel>
            </Card>
        </AccordionItem>
    );
};

export default ProblemCard;
