import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    Heading,
    Link,
    Stack,
    useToast,
    Text,
    Alert,
    AlertIcon,
    Badge,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { AuthContext } from "../../App";
import { FileInputWithLabel } from "../../components/InputWithLabel";
import supabase from "../../config/supabaseClient";

const Statutes = () => {
    const [file, setFile] = useState<File | undefined>();
    const { isAdmin } = useContext(AuthContext);
    const toast = useToast();

    const submitFile = async () => {
        console.log(file);
        if (!file) return;
        const { data, error } = await supabase.storage.from("misc").upload("statutes.pdf", file, {
            cacheControl: "0",
            upsert: true,
        });
        if (error) {
            console.log(error);
        }
        if (data) {
            toast({
                title: "Vedtekter ble lagret",
                description: "Det kan ta litt tid før endringen vises",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            window.location.reload();
        }
    };

    return (
        <Stack alignItems={"center"}>
            <Heading>Vedtekter</Heading>
            {isAdmin && (
                <Accordion allowToggle width={"100%"}>
                    <AccordionItem>
                        <AccordionButton>
                            <Box as="span" flex="1" textAlign="left">
                                Oppdater vedtekter
                                <Badge ml={2} colorScheme="blue">
                                    Admin
                                </Badge>
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            <FileInputWithLabel
                                label={"Last opp nye vedtekter"}
                                onChange={(e) => setFile(e)}
                                value={file}
                                accept={".pdf"}
                            />
                            <Button onClick={submitFile} colorScheme="green" mt={4}>
                                Lagre nye vedtekter
                            </Button>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            )}
            <Alert status="info">
                <AlertIcon />
                <Text>
                    Bruker du en mindre skjerm anbefaler vi å åpne vedtektene{" "}
                    <Link
                        color="blue"
                        href={`${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/misc/statutes.pdf`}
                    >
                        her
                    </Link>
                </Text>
            </Alert>
            <iframe
                title="Vedtekter PDF"
                src={`${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/misc/statutes.pdf`}
                height="850px"
                width="800px"
                style={{ maxWidth: "100%", border: "1px solid gray" }}
            ></iframe>
        </Stack>
    );
};

export default Statutes;
