import Navbar from "./Navbar";
import Footer from "./Footer";
import { Box, HStack, VStack } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";

export const Layout = () => {
    return (
        <>
            <HStack justifyContent={"center"}>
                <Box w="7xl" maxW="100%" px={4}>
                    <Navbar />
                </Box>
            </HStack>
            <VStack alignItems={"center"} mb={4}>
                <Box w="7xl" maxW="100%" px={4}>
                    <Outlet />
                </Box>
            </VStack>
            <Footer />
        </>
    );
};
