const specialCharacters = { "-oe-": "ø", "-ae-": "æ", "-aa-": "å" };

export const getNorwegianName = (name: string | undefined) => {
    if (!name) {
        return "";
    }

    let newName = name;

    for (const [key, value] of Object.entries(specialCharacters)) {
        newName = newName.replaceAll(key, value);
    }
    return newName;
};
