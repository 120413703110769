import { useContext, useEffect, useState } from "react";
import { Stack, Heading, useToast, HStack, Button } from "@chakra-ui/react";
import { BoardMember } from "./components";
import supabase from "../../config/supabaseClient";
import { BoardMemberRow } from "../../types";
import { AuthContext } from "../../App";
import { useNavigate } from "react-router-dom";

const BoardPage = () => {
    const [boardMembers, setBoardMembers] = useState<BoardMemberRow[]>([]);
    const toast = useToast();
    const { isAdmin } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchBoardMembers = async () => {
            const { data, error } = await supabase.from("board_members").select().order("order", { ascending: true });

            if (error) {
                setBoardMembers([]);
                return;
            }

            setBoardMembers(data);
        };

        fetchBoardMembers();
    }, []);

    const handleDelete = async (id: number) => {
        const { error } = await supabase.from("board_members").delete().eq("id", id);

        if (error) {
            toast({ title: "Kunne ikke slette styremedlem", status: "error", duration: 5000, isClosable: true });
            return;
        }

        const { error: imageError } = await supabase.storage.from("images").remove(["board_members/" + id]);

        if (imageError) {
            toast({ title: "Kunne ikke slette bilde", status: "error", duration: 5000, isClosable: true });
        }

        setBoardMembers(boardMembers.filter((member) => member.id !== id));
        toast({ title: "Styremedlem ble slettet", status: "success", duration: 5000, isClosable: true });
    };

    return (
        <Stack>
            <HStack justifyContent={"space-between"}>
                <Heading>Styret</Heading>
                {isAdmin && <Button onClick={() => navigate("/styret/opprett")}>Legg til styremedlem</Button>}
            </HStack>
            {boardMembers.map((boardMember) => (
                <BoardMember
                    key={boardMember.full_name}
                    member={boardMember}
                    handleEdit={isAdmin ? () => navigate("/styret/rediger/" + boardMember.id) : undefined}
                    handleDelete={isAdmin ? () => handleDelete(boardMember.id) : undefined}
                />
            ))}
        </Stack>
    );
};

export default BoardPage;
