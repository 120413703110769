import { ChangeEvent, useEffect, useRef, useState, MouseEvent, useContext } from "react";
import {
    Heading,
    SimpleGrid,
    Stack,
    useToast,
    Image,
    HStack,
    Button,
    Input,
    Text,
    Box,
    IconButton,
} from "@chakra-ui/react";
import supabase from "../../config/supabaseClient";
import { useNavigate, useParams } from "react-router-dom";

import { FileObject } from "@supabase/storage-js";
import { AuthContext } from "../../App";
import { ArrowBackIcon, DeleteIcon } from "@chakra-ui/icons";
import { getNorwegianName } from "./utils";

const validDirectory = (directory: string) => {
    return (
        directory.indexOf("..") === -1 &&
        directory.indexOf("/") === -1 &&
        directory.indexOf("\\") === -1 &&
        directory.indexOf(".") === -1 &&
        directory.indexOf("*") === -1 &&
        directory.indexOf("?") === -1 &&
        directory.indexOf('"') === -1 &&
        directory.indexOf("<") === -1 &&
        directory.indexOf(">") === -1 &&
        directory.indexOf("|") === -1
    );
};

const Gallery = () => {
    const { isAdmin } = useContext(AuthContext);
    const [images, setImages] = useState<FileObject[]>([]);
    const [uploads, setUploads] = useState<FileList>();
    const [uploading, setUploading] = useState(false);
    const hiddenFileInput = useRef<HTMLInputElement>(null);

    const navigate = useNavigate();
    const toast = useToast();

    const { folder } = useParams();
    const CDN_URL = `https://dxkczmnvoadzwabfzont.supabase.co/storage/v1/object/public/images/gallery/${folder}/`;

    useEffect(() => {
        if (!folder) {
            return;
        }

        if (!validDirectory(folder)) {
            navigate("/bildearkiv");
            toast({
                title: "Ugyldig mappe",
                description: "Denne mappen eksisterer ikke",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }

        const fetchImages = async () => {
            const { data, error } = await supabase.storage.from("images").list(`gallery/${folder}`, {
                limit: 100,
                offset: 0,
                sortBy: { column: "name", order: "asc" },
            });

            if (error) {
                toast({
                    title: "Kunne ikke hente bilder",
                    description: error.message,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }

            if (data) {
                setImages(data);
            }
        };
        fetchImages();
    }, [folder, navigate, toast]);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        if (!hiddenFileInput.current) return;
        hiddenFileInput.current.click();
    };

    const handleSubmit = async () => {
        if (!uploads) return;

        setUploading(true);

        const toastId = toast({
            title: "Laster opp bilder",
            status: "info",
            duration: null,
            isClosable: false,
        });

        for (let i = 0; i < uploads.length; i++) {
            const file = uploads[i];
            let currentToastId: string | number | undefined;
            const { data, error } = await supabase.storage
                .from("images")
                .upload(`gallery/${folder}/${file.name}`, file);

            if (error) {
                toast({
                    title: "Kunne ikke laste opp " + file.name + " (bilde nr. " + (i + 1) + ")",
                    description: error.message,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
                toast.close(toastId);
                setUploads(undefined);
                setUploading(false);
                return;
            }

            if (data) {
                if (currentToastId) {
                    toast.close(currentToastId);
                }
                currentToastId = toast({
                    title: "Bilde " + (i + 1) + " av " + uploads.length + " lastet opp",
                    status: "success",
                    duration: null,
                    isClosable: true,
                });
            }
        }

        toast.close(toastId);
        setUploads(undefined);
        setUploading(false);
        window.location.reload();
    };

    const handleDelete = async (image: FileObject) => {
        const confirmText = "Er du sikker på at du vil slette bildet?";
        if (!window.confirm(confirmText)) return;

        const { error: imageError } = await supabase.storage.from("images").remove([`gallery/${folder}/${image.name}`]);

        if (imageError) {
            toast({
                title: "Kunne ikke slette bilde",
                description: imageError.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        toast({
            title: "Bilde slettet",
            description: image.name + " ble slettet",
            status: "success",
            duration: 5000,
            isClosable: true,
        });

        setImages(images.filter((img) => img.name !== image.name));
    };

    return (
        <Stack>
            <Button leftIcon={<ArrowBackIcon />} maxW={"120px"} onClick={() => navigate("/bildearkiv")}>
                Tilbake
            </Button>
            <HStack justifyContent={"space-between"}>
                <Heading>{getNorwegianName(folder)}</Heading>
                {isAdmin ? (
                    <>
                        <Input
                            type="file"
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setUploads(e.target.files ?? undefined)}
                            display="none"
                            accept={"image/*"}
                            ref={hiddenFileInput}
                            multiple
                        />
                        <Stack>
                            {uploads ? (
                                <Button onClick={handleSubmit} colorScheme="green" isDisabled={uploading}>
                                    Bekreft opplasting
                                </Button>
                            ) : (
                                <Button onClick={handleClick}>Last opp bilder</Button>
                            )}
                            <Text>
                                {uploads
                                    ? uploads[0].name +
                                      (uploads.length > 1 ? " + " + (uploads.length - 1) + " others" : "")
                                    : ""}
                            </Text>
                        </Stack>
                    </>
                ) : (
                    <Box />
                )}
            </HStack>
            <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} spacing="40px">
                {images.map((image) => (
                    <Stack spacing={0} alignItems="center">
                        <a href={`${CDN_URL}/${image.name}`}>
                            <Image key={image.name} src={`${CDN_URL}/${image.name}`} alt="" maxH={"300px"} />
                        </a>
                        {isAdmin && (
                            <IconButton
                                aria-label="Delete image"
                                icon={<DeleteIcon />}
                                onClick={() => handleDelete(image)}
                            />
                        )}
                    </Stack>
                ))}
            </SimpleGrid>
        </Stack>
    );
};

export default Gallery;
