import {
    Box,
    Button,
    Card,
    CardBody,
    CardFooter,
    Heading,
    Image,
    Stack,
    useToast,
    HStack,
    Checkbox,
    Text,
} from "@chakra-ui/react";
import { ChangeEvent, useEffect, useState } from "react";
import InputWithLabel, { FileInputWithLabel } from "../../components/InputWithLabel";
import supabase from "../../config/supabaseClient";
import { ProductInsert } from "../../types";
import { useNavigate, useParams } from "react-router-dom";

const CreateProduct = () => {
    const [productData, setProductData] = useState<ProductInsert>({ name: "", price: 0, in_stock: true });
    const [productHasImage, setProductHasImage] = useState(false);
    const [image, setImage] = useState<File>();
    const toast = useToast();
    const navigate = useNavigate();

    // If updating an article, fetch the article data
    const { id } = useParams();
    useEffect(() => {
        if (!id) return;
        const fetchProduct = async () => {
            const { data, error } = await supabase.from("products").select().eq("id", id).single();
            if (error) {
                toast({
                    title: "Kunne ikke finne nyheten",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
                navigate("/butikk");
            }
            if (data) {
                setProductData(data);
            }
        };
        fetchProduct();
    }, [id, navigate, toast]);

    const handleChangeProductData = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setProductData({ ...productData, [(e.target as HTMLInputElement).name]: (e.target as HTMLInputElement).value });
    };

    const handleSubmit = async (toggleArchive: boolean = false) => {
        const { data, error } = await supabase.from("products").upsert(productData).select().single();

        if (error) {
            toast({
                title: "Kunne ikke opprette produkt",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        if (data) {
            if (image) {
                const { error: imageError } = await supabase.storage
                    .from("images")
                    .upload("store/" + data.id, image, { cacheControl: "0", upsert: true });

                if (imageError) {
                    toast({ title: "Kunne ikke lagre bilde", status: "error", duration: 5000, isClosable: true });
                }
            }

            toast({
                title: id ? "Produkt ble oppdatert" : "Produkt ble opprettet",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            navigate("/butikk");
        }
    };

    return (
        <Box>
            <Heading mb={4}>{id ? "Oppdater produkt" : "Opprett et produkt"}</Heading>
            <Card variant={"outline"}>
                <CardBody>
                    <Stack spacing={4}>
                        <InputWithLabel
                            name={"name"}
                            label={"Produktnavn"}
                            value={productData.name}
                            onChange={handleChangeProductData}
                        />
                        <InputWithLabel
                            name={"description"}
                            label={"Beskrivelse"}
                            value={productData.description ?? ""}
                            onChange={handleChangeProductData}
                        />
                        <InputWithLabel
                            name={"price"}
                            label={"Pris"}
                            value={productData.price}
                            onChange={handleChangeProductData}
                            type="number"
                        />

                        {id && (
                            <>
                                {productHasImage && <Text>Produktets bilde</Text>}
                                <Image
                                    height={"150px"}
                                    objectFit={"contain"}
                                    src={`https://dxkczmnvoadzwabfzont.supabase.co/storage/v1/object/public/images/store/${id}`}
                                    alt=""
                                    alignSelf={"start"}
                                    onLoad={() => setProductHasImage(true)}
                                    display={productHasImage ? "block" : "none"}
                                />
                            </>
                        )}
                        <FileInputWithLabel
                            label={productHasImage ? "" : "Produktets bilde"}
                            buttonLabel={productHasImage ? "Erstatt bilde" : "Last opp bilde"}
                            value={image}
                            onChange={(e) => setImage(e)}
                            accept={"image/*"}
                        />
                        <Checkbox
                            name="in_stock"
                            isChecked={productData.in_stock}
                            onChange={(e) => setProductData({ ...productData, in_stock: e.target.checked })}
                        >
                            På lager
                        </Checkbox>
                    </Stack>
                </CardBody>
                <CardFooter>
                    <HStack spacing={2}>
                        <Button onClick={() => handleSubmit()}>{id ? "Oppdater produkt" : "Opprett produkt"}</Button>
                    </HStack>
                </CardFooter>
            </Card>
        </Box>
    );
};

export default CreateProduct;
