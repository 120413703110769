import { Box, Button, Card, CardBody, CardFooter, CardHeader, SimpleGrid, useToast } from "@chakra-ui/react";
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputWithLabel from "../../components/InputWithLabel";
import supabase from "../../config/supabaseClient";
import { MemberUpdate } from "../../types";

const EditMember = () => {
    const [memberInfo, setMemberInfo] = useState<MemberUpdate>({});
    const [changedInfo, setChangedInfo] = useState(false);
    const toast = useToast();
    const navigate = useNavigate();

    const { id } = useParams();
    useEffect(() => {
        if (!id) return;

        const fetchMember = async () => {
            const { data, error } = await supabase.from("members").select().eq("id", id).single();

            if (error) {
                toast({
                    title: "Kunne ikke hente medlemsinfo",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }

            if (data) {
                setMemberInfo(data);
            }
        };

        fetchMember();
    }, [id, toast]);

    const handleChangeMemberInfo = (e: ChangeEvent<HTMLInputElement>) => {
        if (!changedInfo) setChangedInfo(true);
        setMemberInfo({ ...memberInfo, [(e.target as HTMLInputElement).name]: (e.target as HTMLInputElement).value });
    };

    const handleSubmit = async () => {
        if (!id) return;
        const { error } = await supabase
            .from("members")
            .upsert({ ...memberInfo, id: id, build_year: memberInfo.build_year || 0 })
            .eq("id", id);

        if (error) {
            toast({
                title: "Kunne ikke oppdatere info",
                description: error.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } else {
            toast({
                title: "Medlemsinfo ble oppdatert",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            setChangedInfo(false);
            navigate("/medlemmer");
        }
    };

    return (
        <Box>
            <Card variant={"outline"}>
                <CardHeader fontWeight="bold">Medlemsinformasjon</CardHeader>
                <CardBody pt={0}>
                    <SimpleGrid columns={{ md: 2 }} spacing={4}>
                        <InputWithLabel
                            name={"full_name"}
                            label={"Fullt navn"}
                            placeholder="Ola Nordmann"
                            value={memberInfo.full_name || ""}
                            onChange={handleChangeMemberInfo}
                        />
                        <InputWithLabel
                            name={"email"}
                            label={"Kontakt epost"}
                            placeholder="ola@nordmann.no"
                            value={memberInfo.email || ""}
                            onChange={handleChangeMemberInfo}
                        />
                        <InputWithLabel
                            name={"address"}
                            label={"Adresse"}
                            placeholder="Hovedveien 31B"
                            value={memberInfo.address || ""}
                            onChange={handleChangeMemberInfo}
                        />
                        <InputWithLabel
                            name={"zip_code"}
                            label={"Postnr"}
                            placeholder="1365"
                            value={memberInfo.zip_code || ""}
                            onChange={handleChangeMemberInfo}
                        />
                        <InputWithLabel
                            name={"city"}
                            label={"Poststed"}
                            placeholder="Blommenholm"
                            value={memberInfo.city || ""}
                            onChange={handleChangeMemberInfo}
                        />
                        <InputWithLabel
                            name={"country"}
                            label={"Land"}
                            placeholder="Norge"
                            value={memberInfo.country || ""}
                            onChange={handleChangeMemberInfo}
                        />
                        <InputWithLabel
                            name={"phone"}
                            label={"Telefon"}
                            placeholder="+47 12345678"
                            value={memberInfo.phone || ""}
                            onChange={handleChangeMemberInfo}
                        />
                    </SimpleGrid>
                </CardBody>
                <CardHeader fontWeight="bold">Båt-informasjon</CardHeader>
                <CardBody pt={0}>
                    <SimpleGrid columns={{ md: 2 }} spacing={4}>
                        <InputWithLabel
                            name={"boat_type"}
                            label={"Båttype"}
                            placeholder=""
                            value={memberInfo.boat_type || ""}
                            onChange={handleChangeMemberInfo}
                        />
                        <InputWithLabel
                            name={"boat_name"}
                            label={"Båtnavn"}
                            placeholder="Kon Tiki"
                            value={memberInfo.boat_name || ""}
                            onChange={handleChangeMemberInfo}
                        />
                        <InputWithLabel
                            name={"build_year"}
                            label={"Byggeår"}
                            placeholder="1990"
                            value={memberInfo.build_year || ""}
                            onChange={handleChangeMemberInfo}
                        />
                        <InputWithLabel
                            name={"sail_regnr"}
                            label={"Seilnr"}
                            placeholder="NOR-00000"
                            value={memberInfo.sail_regnr || ""}
                            onChange={handleChangeMemberInfo}
                        />
                        <InputWithLabel
                            name={"vhf_nr"}
                            label={"VHF nr"}
                            placeholder="LL-0000"
                            value={memberInfo.vhf_nr || ""}
                            onChange={handleChangeMemberInfo}
                        />
                        <InputWithLabel
                            name={"mmsi_nr"}
                            label={"MSSI nr"}
                            placeholder="250000000"
                            value={memberInfo.mmsi_nr || ""}
                            onChange={handleChangeMemberInfo}
                        />
                        <InputWithLabel
                            name={"designer"}
                            label={"Konstruktør"}
                            placeholder=""
                            value={memberInfo.designer || ""}
                            onChange={handleChangeMemberInfo}
                        />
                    </SimpleGrid>
                </CardBody>
                <CardFooter>
                    <Button isDisabled={!changedInfo} onClick={handleSubmit}>
                        Lagre endringer
                    </Button>
                </CardFooter>
            </Card>
        </Box>
    );
};

export default EditMember;
