import { useEffect, useState } from "react";
import { Heading, Stack, useToast, Accordion, HStack, Text, Select } from "@chakra-ui/react";
import { ProblemRow } from "../../types";
import supabase from "../../config/supabaseClient";
import ProblemCard from "./components/ProblemCard";
import NoContent from "../../components/NoContent";
import { ProblemStatus } from "../../types/enums";

const Problems = () => {
    const [problems, setProblems] = useState<ProblemRow[]>([]);
    const [statusFilter, setStatusFilter] = useState<ProblemStatus>(ProblemStatus.Open);

    const toast = useToast();

    useEffect(() => {
        const fetchProblems = async () => {
            const { data, error } = await supabase.from("problems").select().eq("status", statusFilter);

            if (error) {
                toast({
                    title: "Noe gikk galt",
                    description: "Kunne ikke hente problemer",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
                return;
            }

            setProblems(data);
        };
        fetchProblems();
    }, [toast, statusFilter]);

    const updateProblemStatus = async (id: number, status: ProblemStatus) => {
        const { error } = await supabase.from("problems").update({ status: status }).match({ id });

        if (error) {
            toast({
                title: "Noe gikk galt",
                description: "Kunne ikke oppdatere problemets status",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        const updatedProblems = problems.map((problem) => {
            if (problem.id === id) {
                return { ...problem, status: status };
            }
            return problem;
        });

        setProblems(updatedProblems);
    };

    const Header = () => {
        return (
            <HStack justifyContent={"space-between"}>
                <Heading>Tilbakemeldinger</Heading>
                <Stack>
                    <Text>Filtrer på status</Text>
                    <Select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value as ProblemStatus)}>
                        <option value={ProblemStatus.Open}>Åpen</option>
                        <option value={ProblemStatus.Resolved}>Løst</option>
                        <option value={ProblemStatus.Closed}>Lukk</option>
                    </Select>
                </Stack>
            </HStack>
        );
    };

    if (problems.length === 0) {
        return (
            <Stack>
                <Header />
                <NoContent text="Ingen tilbakemeldinger med denne statusen" />
            </Stack>
        );
    }

    return (
        <Stack>
            <Header />
            <Accordion allowToggle>
                {problems.map((problem) => (
                    <ProblemCard problem={problem} updateProblemStatus={updateProblemStatus} />
                ))}
            </Accordion>
        </Stack>
    );
};

export default Problems;
