export const memberDataFields = [
    {
        title: "Fullt Navn",
        dbName: "full_name",
    },
    {
        title: "Epost",
        dbName: "email",
    },
    {
        title: "Adresse",
        dbName: "address",
    },
    {
        title: "Postnr",
        dbName: "zip_code",
    },
    {
        title: "Poststed",
        dbName: "city",
    },
    {
        title: "Land",
        dbName: "country",
    },
    {
        title: "Tlf",
        dbName: "phone",
    },
    {
        title: "Båttype",
        dbName: "boat_type",
    },
    {
        title: "Båtnavn",
        dbName: "boat_name",
    },
    {
        title: "Byggeår",
        dbName: "build_year",
    },
    {
        title: "Seilnr",
        dbName: "sail_regnr",
    },
    {
        title: "VHF nr",
        dbName: "vhf_nr",
    },
    {
        title: "MSSI nr",
        dbName: "mssi_nr",
    },
    {
        title: "Kontruktør",
        dbName: "designer",
    },
];
