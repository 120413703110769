import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { Box, IconButton, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { BuoyRow } from "../../../types";

interface Props {
    buoys: BuoyRow[];
    onDelete?: (id: number) => void;
    onEdit?: (buoy: BuoyRow) => void;
}

const BuoyTable = ({ buoys, onDelete, onEdit }: Props) => {
    return (
        <Box>
            <TableContainer>
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>Navn</Th>
                            <Th>Kommentar</Th>
                            <Th isNumeric>Breddegrad</Th>
                            <Th isNumeric>Lengdegrad</Th>
                            {(onDelete || onEdit) && <Th>Valg</Th>}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {buoys.map((buoy) => (
                            <Tr key={buoy.name}>
                                <Td>{buoy.name}</Td>
                                <Td>{buoy.comment}</Td>
                                <Td isNumeric>{buoy.lat}</Td>
                                <Td isNumeric>{buoy.long}</Td>
                                {(onDelete || onEdit) && (
                                    <Td>
                                        {onEdit && (
                                            <IconButton
                                                onClick={() => onEdit(buoy)}
                                                icon={<EditIcon />}
                                                aria-label="Rediger bøye"
                                                mr={2}
                                            />
                                        )}
                                        {onDelete && (
                                            <IconButton
                                                onClick={() => onDelete(buoy.id)}
                                                icon={<DeleteIcon />}
                                                aria-label="Slett bøye"
                                            />
                                        )}
                                    </Td>
                                )}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default BuoyTable;
