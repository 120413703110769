import { useEffect, useState } from "react";
import { Box, Button, Heading, HStack, Stack } from "@chakra-ui/react";
import supabase from "../../config/supabaseClient";
import { EventRow } from "../../types";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useContext } from "react";
import { AuthContext } from "../../App";
import NoContent from "../../components/NoContent";
import EventCard from "../../components/events/EventCard";

const Events = () => {
    const [events, setEvents] = useState<EventRow[]>([]);
    const { isAdmin } = useContext(AuthContext);
    const [showOld, setShowOld] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchEvents = async () => {
            const { data, error } = showOld
                ? await supabase
                      .from("events")
                      .select()
                      .lt("date", moment().format("YYYY-MM-DD"))
                      .order("date", { ascending: false })
                : await supabase
                      .from("events")
                      .select()
                      .gte("date", moment().format("YYYY-MM-DD"))
                      .order("date", { ascending: true });

            if (error) {
                setEvents([]);
                return;
            }

            setEvents(data);
        };

        fetchEvents();
    }, [showOld]);

    return (
        <Box>
            <HStack justifyContent={"space-between"} mb={6}>
                <Heading>Aktiviteter</Heading>
                <HStack spacing={2}>
                    <Button onClick={() => setShowOld(!showOld)}>
                        {showOld ? "Kommende aktiviteter" : "Tidligere aktiviteter"}
                    </Button>
                    {isAdmin && <Button onClick={() => navigate("/aktiviteter/opprett")}>Opprett aktivitet</Button>}
                </HStack>
            </HStack>

            {events.length > 0 ? (
                <Stack>
                    {events.map((event) => (
                        <EventCard key={event.id} event={event} />
                    ))}
                </Stack>
            ) : (
                <NoContent text={"Det er ingen kommende aktiviteter"} />
            )}
        </Box>
    );
};

export default Events;
