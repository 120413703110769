import { Box, Heading, Text, VStack } from "@chakra-ui/react";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../App";
import landing from "../../assets/landing.jpg";
import EventCard from "../../components/events/EventCard";
import supabase from "../../config/supabaseClient";
import { EventRow, NewsRow } from "../../types";
import RecentNewsRow from "./components/RecentNewsRow";

const Landing = () => {
    const [recentNews, setRecentNews] = useState<NewsRow[]>([]);
    const [upcomingEvents, setUpcomingEvents] = useState<EventRow[]>([]);
    const { user } = useContext(AuthContext);

    useEffect(() => {
        const fetchNews = async () => {
            const { data, error } = await supabase
                .from("news")
                .select()
                .eq("archived", false)
                .order("last_modified", { ascending: false })
                .limit(3);

            if (error) {
                setRecentNews([]);
                return;
            }

            setRecentNews(data);
        };

        const fetchEvents = async () => {
            const { data, error } = await supabase
                .from("events")
                .select()
                .gte("date", moment().format("YYYY-MM-DD"))
                .order("date", { ascending: true })
                .limit(3);

            if (error) {
                setUpcomingEvents([]);
                return;
            }

            setUpcomingEvents(data);
        };

        fetchNews();
        if (!!user) {
            fetchEvents();
        }
    }, [user]);

    return (
        <VStack spacing="4">
            <Box
                display="flex"
                backgroundImage={landing}
                backgroundSize="cover"
                width={"100%"}
                minHeight={"450px"}
                backgroundAttachment="fixed"
                backgroundPosition="center"
                backgroundRepeat="no-repeat"
                justifyContent="center"
                alignItems={"center"}
            >
                <Box backgroundColor={"rgba(255,255,255,0.4)"} p={3} maxW={"80%"}>
                    <Text
                        fontSize={{ base: "2xl", md: "3xl" }}
                        color={"#0a3391"}
                        alignSelf="start"
                        letterSpacing={"0.3rem"}
                        fontWeight="semibold"
                    >
                        NORSK HAVSEILER- OG KRYSSERKLUBB
                    </Text>
                    <Box color={"#0a3391"} w="700px" maxW="100%" h={"2px"} bg={"#0a3391"} alignSelf="start" />
                    <Text fontSize={"xl"} color={"#0a3391"} alignSelf="start" letterSpacing={"0.1rem"}>
                        NORWEGIAN OCEAN RACING AND CRUISING CLUB
                    </Text>
                </Box>
            </Box>
            <Heading alignSelf={"start"}>Siste nytt</Heading>
            {recentNews.map((article) => (
                <RecentNewsRow key={article.id} article={article} />
            ))}
            {!!user && (
                <>
                    <Heading alignSelf={"start"} mt={2}>
                        Kommende arrangementer
                    </Heading>
                    {upcomingEvents.map((event) => (
                        <EventCard event={event} />
                    ))}
                </>
            )}
        </VStack>
    );
};

export default Landing;
