import { Box, Button, HStack, IconButton, Input, Text, Textarea } from "@chakra-ui/react";
import { ChangeEvent, useRef, MouseEvent } from "react";
import MDEditor, { commands } from "@uiw/react-md-editor";
import { DeleteIcon } from "@chakra-ui/icons";

interface InputProps {
    name: string;
    label: string;
    placeholder?: string;
    variant?: string;
    value: string | number;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    labelHelpIcon?: JSX.Element;
    type?: "text" | "password" | "number" | "date";
}

interface TextareaProps {
    name: string;
    label: string;
    placeholder?: string;
    variant?: string;
    value: string;
    onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

interface FileInputProps {
    label: string;
    buttonLabel?: string;
    value?: File;
    onChange: (e: File | undefined) => void;
    accept?: string;
}

interface MDEditorProps {
    label: string;
    value: string;
    onChange: (value: string | undefined) => void;
}

const InputWithLabel = ({
    label,
    placeholder,
    variant,
    name,
    onChange,
    value,
    labelHelpIcon,
    type = "text",
}: InputProps) => {
    return (
        <Box>
            <HStack>
                <Text ml={1}>{label}</Text>
                {labelHelpIcon && labelHelpIcon}
            </HStack>
            <Input
                name={name}
                placeholder={placeholder}
                variant={variant}
                value={value}
                onChange={onChange}
                type={type}
                _placeholder={{ opacity: 0.4 }}
            />
        </Box>
    );
};

const TextareaWithLabel = ({ label, placeholder, variant, name, onChange, value }: TextareaProps) => {
    return (
        <Box>
            <Text ml={1}>{label}</Text>
            <Textarea
                name={name}
                placeholder={placeholder}
                variant={variant}
                value={value}
                onChange={onChange}
                rows={8}
            />
        </Box>
    );
};

const FileInputWithLabel = ({ label, buttonLabel, onChange, value, accept }: FileInputProps) => {
    const hiddenFileInput = useRef<HTMLInputElement>(null);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        if (!hiddenFileInput.current) return;
        hiddenFileInput.current.click();
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        let fileUploaded;
        if (event.target.files) {
            fileUploaded = event.target.files[0];
        }
        onChange(fileUploaded);
    };

    return (
        <Box>
            <Text ml={1}>{label}</Text>
            <HStack>
                <Button onClick={handleClick}>
                    {value ? "Last opp en annen fil" : buttonLabel ? buttonLabel : " Last opp fil"}
                </Button>
                <Text>{value?.name}</Text>
                {value && (
                    <IconButton
                        variant={"ghost"}
                        icon={<DeleteIcon />}
                        aria-label="Slett bilde"
                        onClick={() => onChange(undefined)}
                    />
                )}
            </HStack>
            <Input type="file" ref={hiddenFileInput} onChange={handleChange} display="none" accept={accept} />
        </Box>
    );
};

const coloredText = {
    name: "coloredText",
    keyCommand: "coloredText",
    buttonProps: { "aria-label": "Insert colored text" },
    icon: (
        <svg stroke="#000000" width="12" height="12" viewBox="0 0 24 24">
            <path d="M5 18h14v3H5zm7.5-14h-1c-.401 0-.764.24-.921.609L5.745 16h2.173l1.273-3h5.604l1.268 3h2.171L13.421 4.61A1 1 0 0 0 12.5 4zm-2.46 7 1.959-4.616L13.95 11h-3.91z"></path>
        </svg>
    ),
    execute: (state: any, api: any) => {
        let modifyText = `<span style="color:blue">${state.selectedText}</span>`;
        if (!state.selectedText) {
            modifyText = `<span style="color:blue">SETT INN TEKST HER</span>`;
        }
        api.replaceSelection(modifyText);
    },
};

const MarkdownEditorWithLabel = ({ label, value, onChange }: MDEditorProps) => {
    return (
        <Box data-color-mode="light">
            <Text ml={1}>{label}</Text>
            <MDEditor
                value={value}
                onChange={onChange}
                commands={[
                    commands.group([commands.title1, commands.title2, commands.title3], {
                        name: "title",
                        groupName: "title",
                        buttonProps: { "aria-label": "Insert title" },
                    }),
                    commands.bold,
                    commands.italic,
                    commands.strikethrough,
                    coloredText,
                    commands.divider,
                    commands.link,
                    commands.quote,
                    commands.image,
                    commands.divider,
                    commands.unorderedListCommand,
                    commands.orderedListCommand,
                    commands.checkedListCommand,
                ]}
            />
        </Box>
    );
};

export { TextareaWithLabel, FileInputWithLabel, MarkdownEditorWithLabel };
export default InputWithLabel;
