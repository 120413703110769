import { useState, useEffect, ChangeEvent, useContext } from "react";
import {
    Box,
    Button,
    Checkbox,
    Heading,
    HStack,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react";
import type { MemberRow } from "../../types";
import { memberDataFields } from "./dataFields";
import { ChevronDownIcon, EditIcon } from "@chakra-ui/icons";
import { PrinterIcon } from "../../components/CustomIcons";

import supabase from "../../config/supabaseClient";
import { AuthContext } from "../../App";
import { useNavigate } from "react-router-dom";

interface OrderByOption {
    displayName: string;
    dbName: string;
}

const orderByOptions = [
    { displayName: "Fullt navn", dbName: "full_name" },
    { displayName: "Båtnavn", dbName: "boat_name" },
    { displayName: "Seilnr", dbName: "sail_regnr" },
];

const Members = () => {
    const [members, setMembers] = useState<MemberRow[]>();
    const [fetchError, setFetchError] = useState<string>("");
    const [orderBy, setOrderBy] = useState<OrderByOption>(orderByOptions[0]);
    const [displayColumns, setDisplayColumns] = useState(
        memberDataFields.map((field) => {
            return { ...field, visible: true };
        })
    );
    const { isAdmin } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchMembers = async () => {
            const { data, error } = await supabase.from("members").select().order(orderBy.dbName);

            if (error) {
                setFetchError("Kunne ikke hente medlemmer");
                setMembers(undefined);
                return;
            }

            setFetchError("");
            setMembers(data);
        };

        fetchMembers();
    }, [orderBy]);

    const handleChangeDisplayColumn = (event: ChangeEvent<HTMLInputElement>) => {
        let temp = [...displayColumns];
        temp[parseInt(event.target.name)].visible = event.target.checked;
        setDisplayColumns(temp);
    };

    if (!members) {
        return (
            <Box>
                <Text>{fetchError}</Text>
            </Box>
        );
    }

    const colums = displayColumns.filter((field) => field.visible);

    return (
        <Box>
            <Heading>Medlemsregister</Heading>
            <HStack my={"2"}>
                <Text fontSize="lg">Sorter på:</Text>
                {orderByOptions.map((option) => (
                    <Button
                        key={option.dbName}
                        onClick={() => setOrderBy(option)}
                        variant={orderBy === option ? "solid" : "outline"}
                        colorScheme="blue"
                        size={"sm"}
                    >
                        {option.displayName}
                    </Button>
                ))}
                <Box flex={1} />
                <Menu closeOnSelect={false}>
                    <MenuButton
                        as={Button}
                        size={"sm"}
                        colorScheme="blue"
                        variant="outline"
                        rightIcon={<ChevronDownIcon fontSize={"xl"} />}
                    >
                        Vis
                    </MenuButton>
                    <MenuList>
                        {displayColumns.map((column, index) => (
                            <MenuItem key={column.dbName} p={0}>
                                <Checkbox
                                    name={index.toString()}
                                    isChecked={column.visible}
                                    onChange={handleChangeDisplayColumn}
                                    py={2}
                                    px={4}
                                >
                                    {column.title}
                                </Checkbox>
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
                <Button
                    variant={"outline"}
                    colorScheme="blue"
                    size={"sm"}
                    leftIcon={<PrinterIcon />}
                    onClick={() => window.print()}
                >
                    Skriv ut
                </Button>
            </HStack>
            <TableContainer border={"1px"} borderRadius="md" borderColor={"gray.200"} overflow="scroll">
                <Table variant={"simple"}>
                    <Thead>
                        <Tr>
                            {isAdmin && <Th>Rediger</Th>}
                            {colums.map((field) => (
                                <Th key={field.dbName}>{field.title}</Th>
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {members.map((member) => (
                            <Tr key={member.id}>
                                {isAdmin && (
                                    <Td>
                                        <IconButton
                                            margin={0}
                                            padding={0}
                                            variant="ghost"
                                            colorScheme="blue"
                                            aria-label="Call Sage"
                                            icon={<EditIcon />}
                                            onClick={() => navigate("/medlemmer/" + member.id)}
                                        />
                                    </Td>
                                )}
                                {colums.map((field) => (
                                    <Td key={field.dbName}>{member[field.dbName as keyof MemberRow]}</Td>
                                ))}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default Members;
