import { Box, Button, Card, CardBody, CardFooter, Heading, Stack, useToast, HStack } from "@chakra-ui/react";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import InputWithLabel, { TextareaWithLabel } from "../../components/InputWithLabel";
import supabase from "../../config/supabaseClient";
import { NoticeInsert } from "../../types";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../App";

const CreateNotice = () => {
    const [notice, setNotice] = useState<NoticeInsert>({} as NoticeInsert);
    const toast = useToast();
    const navigate = useNavigate();
    const { user, isAdmin } = useContext(AuthContext);

    // If updating an article, fetch the article data
    const { id } = useParams();

    useEffect(() => {
        if (!id) return;
        const fetchNotice = async () => {
            const { data, error } = await supabase.from("notices").select().eq("id", id).single();
            if (error) {
                toast({
                    title: "Kunne ikke finne oppslaget",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
                navigate("/oppslagstavle");
            }
            if (data) {
                if (data.user_uid !== user?.id && !isAdmin) {
                    navigate("/oppslagstavle");
                }

                setNotice(data);
            }
        };
        fetchNotice();
    }, [id, navigate, toast, user?.id, isAdmin]);

    const handelChangeNotice = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setNotice({ ...notice, [(e.target as HTMLInputElement).name]: (e.target as HTMLInputElement).value });
    };

    const handleSubmit = async () => {
        const newNotice = {
            ...notice,
            user_uid: notice.user_uid ? notice.user_uid : user?.id,
        };
        const { error } = await supabase.from("notices").upsert(newNotice).select().single();

        if (error) {
            toast({
                title: "Kunne ikke opprette oppslag",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        toast({
            title: id ? "Ditt oppslag ble oppdatert" : "Ditt oppslag ble opprettet",
            status: "success",
            duration: 5000,
            isClosable: true,
        });
        navigate("/oppslagstavle");
    };

    return (
        <Box>
            <Heading mb={4}>{id ? "Oppdater oppslag" : "Opprett et oppslag"}</Heading>
            <Card variant={"outline"}>
                <CardBody>
                    <Stack spacing={4}>
                        <InputWithLabel
                            name={"title"}
                            label={"Overskrift"}
                            value={notice.title ?? ""}
                            onChange={handelChangeNotice}
                        />
                        <InputWithLabel
                            name={"author"}
                            label={"Ditt navn"}
                            value={notice.author ?? ""}
                            onChange={handelChangeNotice}
                        />
                        <InputWithLabel
                            name={"expires"}
                            label={
                                "Utløper - Når skal oppslaget ditt fjernes? Ikke fyll ut denne om du vil at oppslaget skal bli stående "
                            }
                            value={notice.expires ?? ""}
                            onChange={handelChangeNotice}
                            type={"date"}
                        />
                        <TextareaWithLabel
                            name={"text"}
                            label={"Innhold"}
                            value={notice.text ?? ""}
                            onChange={handelChangeNotice}
                        />
                    </Stack>
                </CardBody>
                <CardFooter>
                    <HStack spacing={2}>
                        <Button onClick={() => handleSubmit()}>{id ? "Oppdater oppslag" : "Opprett oppslag"}</Button>
                    </HStack>
                </CardFooter>
            </Card>
        </Box>
    );
};

export default CreateNotice;
