export const getURL = () => {
    let url =
        process.env?.REACT_APP_PUBLIC_SITE_URL ?? // Set this to your site URL in production env.
        process.env?.REACT_APP_VERCEL_URL ?? // Automatically set by Vercel.
        "http://localhost:3000/";
    // Make sure to include `https://` when not localhost.
    url = url.includes("http") ? url : `https://${url}`;
    // Make sure to including trailing `/`.
    url = url.charAt(url.length - 1) === "/" ? url : `${url}/`;
    return url;
};
