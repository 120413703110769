import { useContext, useState } from "react";
import { Button, Heading, Select, Stack, useToast, Text, Box, Image } from "@chakra-ui/react";
import InputWithLabel, { FileInputWithLabel, TextareaWithLabel } from "../../components/InputWithLabel";
import { ProblemInsert } from "../../types";
import { AuthContext } from "../../App";
import { ProblemStatus, ProblemType } from "../../types/enums";
import supabase from "../../config/supabaseClient";
import { useNavigate } from "react-router-dom";

const ReportProblem = () => {
    const [problem, setProblem] = useState<ProblemInsert>();
    const [image, setImage] = useState<File>();
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState<string>();

    const { user } = useContext(AuthContext);

    const toast = useToast();
    const navigate = useNavigate();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setError(undefined);
        setProblem({ ...problem, [e.target.name]: e.target.value });
    };

    const handleSubmit = async () => {
        if (!problem?.title || !problem?.description) {
            setError("Vennligst fyll ut alle tekstfeltene");
            return;
        }

        setSubmitting(true);
        let newProblem: ProblemInsert = { ...problem, status: ProblemStatus.Open };

        if (!newProblem.type) {
            newProblem = { ...newProblem, type: ProblemType.Bug };
        }

        if (user) {
            newProblem = { ...newProblem, user_uid: user.id };
        }

        const { data, error } = await supabase.from("problems").insert(newProblem).select().single();

        if (error) {
            toast({
                title: "Noe gikk galt",
                description: "Kunne ikke sende inn problem",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            setSubmitting(false);
            return;
        }

        if (image) {
            const { error: imageError } = await supabase.storage
                .from("images_open")
                .upload("problems/" + data.id, image, { cacheControl: "0", upsert: true });

            if (imageError) {
                toast({
                    title: "Kunne ikke lagre bilde",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        }

        toast({
            title: "Vi har mottat din tilbakemelding",
            status: "success",
            duration: 5000,
            isClosable: true,
        });
        navigate("/");
    };

    return (
        <Stack spacing={4}>
            <Heading>Gi tilbakemelding</Heading>
            <InputWithLabel name="title" label="Tittel" value={problem?.title || ""} onChange={handleChange} />
            <Box>
                <Text ml={1}>Type tilbakemelding</Text>
                <Select value={problem?.type} onChange={(e) => setProblem({ ...problem, type: e.target.value })}>
                    <option value={ProblemType.Bug}>Feil/Bug</option>
                    <option value={ProblemType.Feature}>Ny funksjon</option>
                    <option value={ProblemType.Enhancement}>Forbedring av eksisterende funksjon</option>
                </Select>
            </Box>
            <TextareaWithLabel
                name={"description"}
                label={"Beskrivelse av problem/forslag"}
                value={problem?.description || ""}
                onChange={handleChange}
            />
            <FileInputWithLabel
                label={image ? "" : "Last opp bilde av ditt problem"}
                value={image}
                onChange={(e) => setImage(e)}
                accept={"image/*"}
            />
            {image && <Text>Opplastet bilde</Text>}
            {image && (
                <Image
                    height={"150px"}
                    objectFit={"contain"}
                    src={URL.createObjectURL(image as Blob)}
                    alt=""
                    alignSelf={"start"}
                    display={image ? "block" : "none"}
                />
            )}
            <Text color={"red"}>{error}</Text>
            <Button colorScheme={"blue"} onClick={handleSubmit} isDisabled={submitting || !!error}>
                Send inn tilbakemelding
            </Button>
        </Stack>
    );
};

export default ReportProblem;
