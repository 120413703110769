import {
    Box,
    Button,
    Card,
    CardBody,
    CardFooter,
    Heading,
    Stack,
    useToast,
    Checkbox,
    Text,
    Image,
} from "@chakra-ui/react";
import { ChangeEvent, useEffect, useState } from "react";
import InputWithLabel, {
    TextareaWithLabel,
    MarkdownEditorWithLabel,
    FileInputWithLabel,
} from "../../components/InputWithLabel";
import supabase from "../../config/supabaseClient";
import { EventInsert } from "../../types";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

const CreateEvent = () => {
    const [event, setEvent] = useState<EventInsert>({ date: moment().format("YYYY-MM-DD") } as EventInsert);
    const [image, setImage] = useState<File>();
    const [eventHasImage, setEventHasImage] = useState(false);
    const toast = useToast();
    const navigate = useNavigate();

    // If updating an article, fetch the article data
    const { id } = useParams();
    useEffect(() => {
        if (!id) return;
        const fetchEvent = async () => {
            const { data, error } = await supabase.from("events").select().eq("id", id).single();
            if (error) {
                toast({
                    title: "Kunne ikke finne aktiviteten",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
                navigate("/aktiviteter");
            }
            if (data) {
                setEvent(data);
            }
        };
        fetchEvent();
    }, [id, navigate, toast]);

    const handleChangeData = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEvent({ ...event, [(e.target as HTMLInputElement).name]: (e.target as HTMLInputElement).value });
    };

    const handleSubmit = async (toggleArchive: boolean = false) => {
        const { data, error } = await supabase.from("events").upsert(event).select().single();

        if (error) {
            toast({
                title: "Kunne ikke opprette aktivitet",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        if (data) {
            if (image) {
                const { error: imageError } = await supabase.storage
                    .from("images")
                    .upload("events/" + data.id, image, { cacheControl: "0", upsert: true });

                if (imageError) {
                    toast({ title: "Kunne ikke lagre bilde", status: "error", duration: 5000, isClosable: true });
                }
            }
            toast({
                title: id ? "Aktiviteten ble oppdatert" : "Aktiviteten ble opprettet",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            navigate(`/aktiviteter/aktivitet/${data.id}`);
        }
    };

    return (
        <Box>
            <Heading mb={4}>{id ? "Oppdater aktivitet" : "Opprett aktivitet"}</Heading>
            <Card variant={"outline"}>
                <CardBody>
                    <Stack spacing={4}>
                        <InputWithLabel
                            name={"name"}
                            label={"Navn"}
                            value={event.name ?? ""}
                            onChange={handleChangeData}
                        />
                        <InputWithLabel
                            name={"short_description"}
                            label={"Kort besrkivelse"}
                            value={event.short_description ?? ""}
                            onChange={handleChangeData}
                        />
                        <InputWithLabel
                            name={"date"}
                            label={"Dato"}
                            value={event.date ?? ""}
                            onChange={handleChangeData}
                            type={"date"}
                        />
                        {id && (
                            <>
                                {eventHasImage && <Text>Aktivitetens bilde</Text>}
                                <Image
                                    height={"150px"}
                                    objectFit={"contain"}
                                    src={`https://dxkczmnvoadzwabfzont.supabase.co/storage/v1/object/public/images/events/${id}`}
                                    alt=""
                                    alignSelf={"start"}
                                    onLoad={() => setEventHasImage(true)}
                                    display={eventHasImage ? "block" : "none"}
                                />
                            </>
                        )}
                        <FileInputWithLabel
                            label={eventHasImage ? "" : "Aktivitetens bilde"}
                            buttonLabel={eventHasImage ? "Erstatt bilde" : "Last opp bilde"}
                            value={image}
                            onChange={(e) => setImage(e)}
                            accept={"image/*"}
                        />
                        <Checkbox
                            name="rich_text"
                            isChecked={event.rich_text}
                            onChange={(e) => setEvent({ ...event, rich_text: e.target.checked })}
                        >
                            Bruk rik tekst
                        </Checkbox>
                        {event.rich_text ? (
                            <MarkdownEditorWithLabel
                                label={"Beskrivelse"}
                                value={event.text ?? ""}
                                onChange={(value) => setEvent({ ...event, text: value })}
                            />
                        ) : (
                            <TextareaWithLabel
                                name={"text"}
                                label={"Innhold"}
                                value={event.text ?? ""}
                                onChange={handleChangeData}
                            />
                        )}
                    </Stack>
                </CardBody>
                <CardFooter>
                    <Button onClick={() => handleSubmit()} isDisabled={!event.date}>
                        {id ? "Oppdater aktivitet" : "Opprett aktivitet"}
                    </Button>
                </CardFooter>
            </Card>
        </Box>
    );
};

export default CreateEvent;
