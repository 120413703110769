import { useEffect, useState } from "react";
import { Button, Card, CardBody, Heading, Text, HStack, Stack } from "@chakra-ui/react";
import supabase from "../../config/supabaseClient";
import { RegattaRow } from "../../types";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useContext } from "react";
import { AuthContext } from "../../App";
import NoContent from "../../components/NoContent";

const News = () => {
    const [regattas, setRegattas] = useState<RegattaRow[]>([]);
    const { isAdmin } = useContext(AuthContext);
    const [showOld, setShowOld] = useState(false);
    const [hasFetched, setHasFetched] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchNews = async () => {
            const { data, error } = showOld
                ? await supabase
                      .from("regattas")
                      .select()
                      .lt("date", moment().format("YYYY-MM-DD"))
                      .order("date", { ascending: false })
                : await supabase
                      .from("regattas")
                      .select()
                      .gte("date", moment().format("YYYY-MM-DD"))
                      .order("date", { ascending: true });

            if (error) {
                setRegattas([]);
                return;
            }

            setRegattas(data);
            setHasFetched(true);
        };

        fetchNews();
    }, [showOld]);

    const PageToolbar = () => {
        return (
            <HStack justifyContent={"space-between"} mb={6}>
                <Heading>Seilaser</Heading>
                <HStack spacing={2}>
                    <Button
                        onClick={() => {
                            setHasFetched(false);
                            setShowOld(!showOld);
                        }}
                    >
                        {showOld ? "Kommende seilaser" : "Tidligere seilaser"}
                    </Button>
                    {isAdmin && <Button onClick={() => navigate("/seilaser/opprett")}>Opprett seilas</Button>}
                </HStack>
            </HStack>
        );
    };

    if (regattas.length === 0 && hasFetched) {
        return (
            <Stack>
                <PageToolbar />
                <NoContent text={"Det er ingen kommende seilaser"} />
            </Stack>
        );
    }

    return (
        <Stack>
            <PageToolbar />
            {regattas.map((regatta) => (
                <Card
                    key={regatta.id}
                    variant="outline"
                    direction={"row"}
                    overflow="hidden"
                    borderColor={"gray.400"}
                    width={"100%"}
                    onClick={() => navigate(`/seilaser/seilas/${regatta.id}`)}
                    sx={{ cursor: "pointer" }}
                    alignItems={"center"}
                >
                    <Stack alignItems={"center"} p={2} spacing={0} ml={2}>
                        <Text fontSize={"md"} fontWeight="bold">
                            {moment(regatta.date).format("ddd")}
                        </Text>
                        <Heading mt={0}>{moment(regatta.date).format("DD")}</Heading>
                        <Text fontSize={"md"} fontWeight="bold">
                            {moment(regatta.date).format("MMM").toUpperCase()}
                        </Text>
                    </Stack>
                    <CardBody>
                        <Heading size={"md"}>{regatta.name}</Heading>
                        <Text size={"md"}>{regatta.short_description}</Text>
                    </CardBody>
                </Card>
            ))}
        </Stack>
    );
};

export default News;
