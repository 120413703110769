import { Box, Text } from "@chakra-ui/react";
import { LatLngExpression } from "leaflet";
import { useEffect, useState } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { BuoyRow } from "../../../types";

interface Props {
    buoys: BuoyRow[];
}

const BuoyMap = ({ buoys }: Props) => {
    const [initialCenter, setInitialCenter] = useState<LatLngExpression>();

    useEffect(() => {
        if (buoys.length === 0) return;

        const buoysInViewport = buoys.filter((buoy) => buoy.affect_viewport === true);
        if (buoysInViewport.length === 0) {
            setInitialCenter([58.86043579690258, 9.932234796049398]);
            return;
        }

        if (buoysInViewport.length === 1) {
            setInitialCenter([buoysInViewport[0].lat, buoysInViewport[0].long]);
            return;
        }

        // Find center lat of all points
        const minLat = buoysInViewport.reduce((prev, curr) => (curr.lat < prev.lat ? curr : prev), {
            lat: Infinity,
        }).lat;
        const maxLat = buoysInViewport.reduce((prev, curr) => (curr.lat > prev.lat ? curr : prev), {
            lat: -Infinity,
        }).lat;
        const midLat = minLat === maxLat ? minLat : minLat + (maxLat - minLat) / 2;

        // Find center long of all points
        const minLong = buoysInViewport.reduce((prev, curr) => (curr.long < prev.long ? curr : prev), {
            long: Infinity,
        }).long;
        const maxLong = buoysInViewport.reduce((prev, curr) => (curr.long > prev.long ? curr : prev), {
            long: -Infinity,
        }).long;
        const midLong = minLong === maxLong ? minLong : minLong + (maxLong - minLong) / 2;

        setInitialCenter([midLat, midLong]);
    }, [buoys]);

    if (!initialCenter || buoys.length === 0) {
        return (
            <Box>
                <Text>Laster inn kart</Text>
            </Box>
        );
    }

    return (
        <Box>
            <MapContainer center={initialCenter} zoom={7} scrollWheelZoom={false} style={{ height: "500px" }}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {buoys.map((buoy) => (
                    <Marker key={buoy.name} position={[buoy.lat, buoy.long]}>
                        <Popup>{buoy.name}</Popup>
                    </Marker>
                ))}
            </MapContainer>
        </Box>
    );
};

export default BuoyMap;
