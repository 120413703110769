import { useContext, useEffect, useState } from "react";
import {
    Heading,
    Stack,
    Text,
    HStack,
    Box,
    IconButton,
    useDisclosure,
    useToast,
    Button,
    Image,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { EventRow } from "../../types";
import supabase from "../../config/supabaseClient";
import moment from "moment";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { AuthContext } from "../../App";
import MDEditor from "@uiw/react-md-editor";

const Event = () => {
    const { id } = useParams();
    const { isAdmin } = useContext(AuthContext);
    const [event, setEvent] = useState<EventRow>();
    const [hasImage, setHasImage] = useState(false);
    const [showFullImage, setShowFullImage] = useState(false);
    const [fetchError, setFetchError] = useState<string>();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const navigate = useNavigate();

    useEffect(() => {
        if (!id) return;

        const fetchEvent = async () => {
            const { data, error } = await supabase.from("events").select().eq("id", id).single();

            if (error) {
                setFetchError("Kunne ikke hente aktivitet");
                setEvent(undefined);
                return;
            }

            setFetchError("");
            setEvent(data);
        };
        fetchEvent();
    }, [id]);

    const handleDelete = async () => {
        onClose();
        if (!id) return;
        const { error } = await supabase.from("events").delete().eq("id", id);
        if (error) {
            toast({
                title: "Kunne ikke slette aktivitet",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        const { error: imgError } = await supabase.storage.from("images").remove([`events/${id}`]);
        if (imgError) {
            toast({
                title: "Kunne ikke slette aktivitetens bilde",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }

        toast({
            title: "Aktivitet slettet",
            status: "success",
            duration: 5000,
            isClosable: true,
        });
        navigate("/aktiviteter");
    };

    if (!event) {
        return <Text>{fetchError}</Text>;
    }

    return (
        <Stack spacing={2}>
            <HStack justifyContent={"space-between"}>
                <HStack spacing={4}>
                    <Stack alignItems={"center"} spacing={0}>
                        <Text fontSize={"md"} fontWeight="bold">
                            {moment(event.date).format("ddd")}
                        </Text>
                        <Heading mt={0}>{moment(event.date).format("DD")}</Heading>
                        <Text fontSize={"md"} fontWeight="bold">
                            {moment(event.date).format("MMM").toUpperCase()}
                        </Text>
                    </Stack>
                    <Heading>{event.name}</Heading>
                </HStack>
                <Box>
                    {hasImage && (
                        <Button size={"sm"} onClick={() => setShowFullImage(!showFullImage)}>
                            {showFullImage ? "Krymp bildet" : "Vis hele bildet"}
                        </Button>
                    )}
                    <Box display={isAdmin ? "block" : "none"}>
                        <IconButton
                            variant={"ghost"}
                            icon={<EditIcon />}
                            aria-label="Rediger aktivitet"
                            onClick={() => navigate(`/aktiviteter/rediger/${event.id}`)}
                        />
                        <IconButton
                            variant={"ghost"}
                            icon={<DeleteIcon />}
                            aria-label="Slett aktivitet"
                            onClick={onOpen}
                        />
                    </Box>
                </Box>
            </HStack>
            <Image
                src={`https://dxkczmnvoadzwabfzont.supabase.co/storage/v1/object/public/images/events/${event.id}`}
                alt=""
                borderRadius="lg"
                h={"auto"}
                maxH={showFullImage ? "" : "400px"}
                objectFit="cover"
                width={"100%"}
                onLoad={() => setHasImage(true)}
                display={hasImage ? "block" : "none"}
            />
            {event.rich_text ? (
                <Box data-color-mode="light">
                    <MDEditor.Markdown source={event.text ?? ""} />
                </Box>
            ) : (
                <Text whiteSpace={"pre-line"}>{event.text}</Text>
            )}
            <ConfirmationDialog
                isOpen={isOpen}
                title={"Slette aktivitet?"}
                body={"Er du sikker på at du vil slette denne aktiviteten?"}
                confirmLabel={"Ja, slett"}
                onClose={onClose}
                onConfirm={handleDelete}
            />
        </Stack>
    );
};

export default Event;
