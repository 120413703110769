import {
    Box,
    Button,
    Card,
    CardBody,
    CardFooter,
    Heading,
    Stack,
    useToast,
    Checkbox,
    Text,
    Image,
} from "@chakra-ui/react";
import { ChangeEvent, useEffect, useState } from "react";
import InputWithLabel, {
    TextareaWithLabel,
    MarkdownEditorWithLabel,
    FileInputWithLabel,
} from "../../components/InputWithLabel";
import supabase from "../../config/supabaseClient";
import { RegattaInsert } from "../../types";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

const CreateRegatta = () => {
    const [regatta, setRegatta] = useState<RegattaInsert>({ date: moment().format("YYYY-MM-DD") } as RegattaInsert);
    const [image, setImage] = useState<File>();
    const [regattaHasImage, setRegattaHasImage] = useState(false);
    const toast = useToast();
    const navigate = useNavigate();

    // If updating an article, fetch the article data
    const { id } = useParams();
    useEffect(() => {
        if (!id) return;
        const fetchArticle = async () => {
            const { data, error } = await supabase.from("regattas").select().eq("id", id).single();
            if (error) {
                toast({
                    title: "Kunne ikke finne seilasen",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
                navigate("/seilaser");
            }
            if (data) {
                setRegatta(data);
            }
        };
        fetchArticle();
    }, [id, navigate, toast]);

    const handleChangeData = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRegatta({ ...regatta, [(e.target as HTMLInputElement).name]: (e.target as HTMLInputElement).value });
    };

    const handleSubmit = async (toggleArchive: boolean = false) => {
        const { data, error } = await supabase.from("regattas").upsert(regatta).select().single();

        if (error) {
            toast({
                title: "Kunne ikke opprette seilas",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        if (data) {
            if (image) {
                const { error: imageError } = await supabase.storage
                    .from("images")
                    .upload("regattas/" + data.id, image, { cacheControl: "0", upsert: true });

                if (imageError) {
                    toast({ title: "Kunne ikke lagre bilde", status: "error", duration: 5000, isClosable: true });
                }
            }
            toast({
                title: id ? "Seilasen ble oppdatert" : "Seilasen ble opprettet",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            navigate(`/seilaser/seilas/${data.id}`);
        }
    };

    return (
        <Box>
            <Heading mb={4}>{id ? "Oppdater seilas" : "Opprett seilas"}</Heading>
            <Card variant={"outline"}>
                <CardBody>
                    <Stack spacing={4}>
                        <InputWithLabel
                            name={"name"}
                            label={"Navn"}
                            value={regatta.name ?? ""}
                            onChange={handleChangeData}
                        />
                        <InputWithLabel
                            name={"short_description"}
                            label={"Kort besrkivelse"}
                            value={regatta.short_description ?? ""}
                            onChange={handleChangeData}
                        />
                        <InputWithLabel
                            name={"date"}
                            label={"Dato"}
                            value={regatta.date ?? ""}
                            onChange={handleChangeData}
                            type={"date"}
                        />
                        {id && (
                            <>
                                {regattaHasImage && <Text>Seilasens bilde</Text>}
                                <Image
                                    height={"150px"}
                                    objectFit={"contain"}
                                    src={`https://dxkczmnvoadzwabfzont.supabase.co/storage/v1/object/public/images/regattas/${id}`}
                                    alt=""
                                    alignSelf={"start"}
                                    onLoad={() => setRegattaHasImage(true)}
                                    display={regattaHasImage ? "block" : "none"}
                                />
                            </>
                        )}
                        <FileInputWithLabel
                            label={regattaHasImage ? "" : "Seilasens bilde"}
                            buttonLabel={regattaHasImage ? "Erstatt bilde" : "Last opp bilde"}
                            value={image}
                            onChange={(e) => setImage(e)}
                            accept={"image/*"}
                        />
                        <Checkbox
                            name="rich_text"
                            isChecked={regatta.rich_text}
                            onChange={(e) => setRegatta({ ...regatta, rich_text: e.target.checked })}
                        >
                            Bruk rik tekst
                        </Checkbox>
                        {regatta.rich_text ? (
                            <MarkdownEditorWithLabel
                                label={"Beskrivelse"}
                                value={regatta.text ?? ""}
                                onChange={(value) => setRegatta({ ...regatta, text: value })}
                            />
                        ) : (
                            <TextareaWithLabel
                                name={"text"}
                                label={"Innhold"}
                                value={regatta.text ?? ""}
                                onChange={handleChangeData}
                            />
                        )}
                    </Stack>
                </CardBody>
                <CardFooter>
                    <Button onClick={() => handleSubmit()} isDisabled={!regatta.date}>
                        {id ? "Oppdater seilas" : "Opprett seilas"}
                    </Button>
                </CardFooter>
            </Card>
        </Box>
    );
};

export default CreateRegatta;
