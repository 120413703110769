import { Button, Card, CardBody, CardFooter, Heading, Stack, Image, Text, useToast } from "@chakra-ui/react";
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputWithLabel, { FileInputWithLabel } from "../../components/InputWithLabel";
import supabase from "../../config/supabaseClient";
import { BoardMemberInsert } from "../../types";

const EditBoardMember = () => {
    const [boardMember, setBoardMember] = useState<BoardMemberInsert>({} as BoardMemberInsert);
    const toast = useToast();
    const navigate = useNavigate();
    const [image, setImage] = useState<File>();
    const [deletable, setDeletable] = useState(false);

    // If updating an article, fetch the article data
    const { id } = useParams();
    useEffect(() => {
        if (!id) return;
        const fetchBoardMember = async () => {
            const { data, error } = await supabase.from("board_members").select().eq("id", id).single();
            if (error) {
                toast({
                    title: "Kunne ikke hente styremedlem",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
                navigate("/styret");
            }
            if (data) {
                setBoardMember(data);
            }
        };
        fetchBoardMember();
    }, [id, navigate, toast]);

    const handleSubmit = async () => {
        if (!boardMember) return;

        const { data, error } = await supabase.from("board_members").upsert(boardMember).select().single();

        if (error) {
            toast({ title: "Kunne ikke oppdatere info", status: "error", duration: 5000, isClosable: true });
        }

        if (data) {
            if (image) {
                const { error: imageError } = await supabase.storage
                    .from("images")
                    .upload("board_members/" + data.id, image, { cacheControl: "0", upsert: true });

                if (imageError) {
                    console.log(imageError);
                    toast({ title: "Kunne ikke lagre bilde", status: "error", duration: 5000, isClosable: true });
                }
            }

            navigate("/styret");
            toast({ title: "Medlem ble lagret", status: "success", duration: 5000, isClosable: true });
        }
    };

    const handleDeleteImage = async () => {
        if (!id) return;
        const { error: imageError } = await supabase.storage.from("images").remove(["board_members/" + id]);

        if (imageError) {
            toast({ title: "Kunne ikke slette bilde", status: "error", duration: 5000, isClosable: true });
            return;
        }

        toast({ title: "Bilde ble slettet", status: "success", duration: 5000, isClosable: true });
    };

    const handleChangeBoardMemberData = (e: ChangeEvent<HTMLInputElement>) => {
        setBoardMember({ ...boardMember, [e.target.name]: e.target.value });
    };

    return (
        <Stack spacing={2}>
            <Heading>{id ? "Rediger styremedlem" : "Legg til styremedlem"}</Heading>
            <Card variant="outline">
                <CardBody>
                    <InputWithLabel
                        name={"full_name"}
                        label={"Fullt navn"}
                        value={boardMember.full_name}
                        onChange={handleChangeBoardMemberData}
                    />
                    <InputWithLabel
                        name={"role"}
                        label={"Verv"}
                        value={boardMember.role}
                        onChange={handleChangeBoardMemberData}
                    />
                    <InputWithLabel
                        name={"email"}
                        label={"Kontakt-epost"}
                        value={boardMember.email ?? ""}
                        onChange={handleChangeBoardMemberData}
                    />
                    <InputWithLabel
                        name={"phone"}
                        label={"Kontakt-tlf"}
                        value={boardMember.phone ?? ""}
                        onChange={handleChangeBoardMemberData}
                    />
                    <InputWithLabel
                        name={"order"}
                        label={"Rekkefølge - Lavere tall havner først"}
                        value={boardMember.order || 0}
                        onChange={handleChangeBoardMemberData}
                    />
                    <FileInputWithLabel
                        value={image}
                        label="Portrettbilde"
                        onChange={(e) => setImage(e)}
                        accept="image/*"
                    />
                    {id && !image && (
                        <>
                            <Image
                                src={
                                    "https://dxkczmnvoadzwabfzont.supabase.co/storage/v1/object/public/images/board_members/" +
                                    id
                                }
                                onLoad={() => setDeletable(true)}
                                borderRadius={"70% 30% 70% 30% / 24% 29% 71% 76% "}
                                w={"200px"}
                                h={"200px"}
                            />
                            {deletable && (
                                <>
                                    <Button
                                        onClick={() => {
                                            handleDeleteImage();
                                            window.location.reload();
                                        }}
                                    >
                                        Slett eksisterende bilde
                                    </Button>
                                    <Text fontSize={"sm"}>
                                        Merk! Dette vil skje umiddelbart ved klikk av knappen over
                                    </Text>
                                </>
                            )}
                        </>
                    )}
                </CardBody>
                <CardFooter>
                    <Button onClick={handleSubmit}>{id ? "Lagre endringer" : "Opprett"}</Button>
                </CardFooter>
            </Card>
        </Stack>
    );
};

export default EditBoardMember;
