import { useContext, useEffect, useState } from "react";
import {
    Heading,
    Stack,
    Text,
    Image,
    HStack,
    Button,
    Box,
    IconButton,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { NewsRow } from "../../types";
import supabase from "../../config/supabaseClient";
import moment from "moment";
import { DeleteIcon, EditIcon, TimeIcon, CalendarIcon } from "@chakra-ui/icons";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { AuthContext } from "../../App";
import MDEditor from "@uiw/react-md-editor";

const NewsArticle = () => {
    const { id } = useParams();
    const { isAdmin } = useContext(AuthContext);
    const [article, setArticle] = useState<NewsRow>();
    const [fetchError, setFetchError] = useState<string>();
    const [hasImage, setHasImage] = useState(false);
    const [showFullImage, setShowFullImage] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const navigate = useNavigate();

    useEffect(() => {
        if (!id) return;

        const fetchArticle = async () => {
            const { data, error } = await supabase.from("news").select().eq("id", id).single();

            if (error) {
                setFetchError("Kunne ikke hente artikkel");
                setArticle(undefined);
                return;
            }

            setFetchError("");
            setArticle(data);
        };
        fetchArticle();
    }, [id]);

    const handleDelete = async () => {
        onClose();
        if (!id) return;
        const { error } = await supabase.from("news").delete().eq("id", id);
        if (error) {
            toast({
                title: "Kunne ikke slette artikkel",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        const { error: imgError } = await supabase.storage.from("images").remove([`news/${id}`]);

        if (imgError) {
            toast({
                title: "Kunne ikke slette artikkelens bilde",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }

        toast({
            title: "Nyhet slettet",
            status: "success",
            duration: 5000,
            isClosable: true,
        });
        navigate("/nyheter");
    };

    if (!article) {
        return <Text>{fetchError}</Text>;
    }

    return (
        <Stack spacing={2}>
            <HStack justifyContent={"space-between"}>
                <Heading>{article.title}</Heading>
                <Box display={isAdmin ? "block" : "none"}>
                    <IconButton
                        variant={"ghost"}
                        icon={<EditIcon />}
                        aria-label="Rediger artikkel"
                        onClick={() => navigate(`/nyheter/opprett/${article.id}`)}
                    />
                    <IconButton variant={"ghost"} icon={<DeleteIcon />} aria-label="Slett artikkel" onClick={onOpen} />
                </Box>
            </HStack>
            <HStack justifyContent={"space-between"}>
                <Text color={"gray"}>
                    {`Opprettet: ${moment(article.created_at).format("HH:mm")}`}
                    <TimeIcon mb={1} ml={1} /> {moment(article.created_at).format("D. MMM YYYY")}
                    <CalendarIcon mb={1} ml={1} />
                </Text>
                {hasImage && (
                    <Button size={"sm"} onClick={() => setShowFullImage(!showFullImage)}>
                        {showFullImage ? "Krymp bildet" : "Vis hele bildet"}
                    </Button>
                )}
            </HStack>
            <Text fontWeight={"bold"} fontSize="lg">
                {article.short_description}
            </Text>
            <Image
                src={`https://dxkczmnvoadzwabfzont.supabase.co/storage/v1/object/public/images/news/${article.id}`}
                alt=""
                borderRadius="lg"
                h={"auto"}
                maxH={showFullImage ? "" : "400px"}
                objectFit="cover"
                width={"100%"}
                onLoad={() => setHasImage(true)}
                display={hasImage ? "block" : "none"}
            />
            {article.rich_text ? (
                <Box data-color-mode="light">
                    <MDEditor.Markdown source={article.text ?? ""} />
                </Box>
            ) : (
                <Text whiteSpace={"pre-line"}>{article.text}</Text>
            )}
            <ConfirmationDialog
                isOpen={isOpen}
                title={"Slette artikkel?"}
                body={"Er du sikker på at du vil slette denne artikkelen?"}
                confirmLabel={"Ja, slett"}
                onClose={onClose}
                onConfirm={handleDelete}
            />
        </Stack>
    );
};

export default NewsArticle;
