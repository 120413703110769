import { useEffect, useState } from "react";
import { Card, CardBody, Heading, Stack, Text, Image } from "@chakra-ui/react";
import supabase from "../../config/supabaseClient";
import { FileObject } from "@supabase/storage-js";
import thumbnail from "./assets/cover.jpg";

const CDN_URL = `https://dxkczmnvoadzwabfzont.supabase.co/storage/v1/object/public/files/neptune/`;

const Neptun = () => {
    const [editions, setEditions] = useState<FileObject[]>([]);

    useEffect(() => {
        const fetchImages = async () => {
            const { data } = await supabase.storage.from("files").list("neptune", {
                limit: 100,
                offset: 0,
                sortBy: { column: "name", order: "desc" },
            });
            if (data) {
                console.log(data);
                setEditions(data);
            }
        };
        fetchImages();
    }, []);

    return (
        <Stack spacing={4}>
            <Heading>Neptuns Arkiv</Heading>
            {editions.map((edition) => (
                <a href={CDN_URL + edition.name} key={edition.name}>
                    <Card
                        variant={"outline"}
                        direction="row"
                        overflow={"hidden"}
                        borderColor={"gray.400"}
                        width={"100%"}
                        alignItems={"center"}
                    >
                        <Image src={thumbnail} borderRadius="lg" objectFit="cover" maxW={"200px"} />
                        <CardBody>
                            <Text>{edition.name}</Text>
                        </CardBody>
                    </Card>
                </a>
            ))}
        </Stack>
    );
};

export default Neptun;
