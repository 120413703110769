import { Button, FormControl, FormLabel, Heading, HStack, Stack, Switch, useToast } from "@chakra-ui/react";
import { ChangeEvent, useEffect, useState } from "react";
import { MapContainer, Marker, TileLayer, useMapEvents } from "react-leaflet";
import InputWithLabel from "../../components/InputWithLabel";
import supabase from "../../config/supabaseClient";
import { BuoyInsert, BuoyRow } from "../../types";
import BuoyMap from "./components/BuoyMap";
import BuoyTable from "./components/BuoyTable";

const EditBuoys = () => {
    const [buoys, setBuoys] = useState<BuoyRow[]>([]);
    const [buoyData, setBuoyData] = useState<BuoyInsert | null>(null);
    const toast = useToast();

    const LocationMarker = () => {
        const map = useMapEvents({
            click(e) {
                if (!buoyData) return;
                setBuoyData({ ...buoyData, lat: e.latlng.lat, long: e.latlng.lng });
                map.flyTo(e.latlng, map.getZoom());
            },
        });

        if (!buoyData) return null;
        return !buoyData.lat || !buoyData.long ? null : <Marker position={[buoyData.lat, buoyData.long]} />;
    };

    useEffect(() => {
        const fetchBuoys = async () => {
            const { data, error } = await supabase.from("buoys").select();

            if (error) {
                setBuoys([]);
                return;
            }

            setBuoys(data);
        };

        fetchBuoys();
    }, []);

    const handleSubmit = async () => {
        if (!buoyData) return;

        const { data, error } = await supabase.from("buoys").upsert(buoyData).select().single();

        if (error) {
            toast({ title: "Kunne ikke lagre bøye", status: "error", duration: 5000, isClosable: true });
        }

        if (data) {
            if (buoyData.id) {
                setBuoys([...buoys.filter((buoy) => buoy.id !== buoyData.id), data]);
                toast({ title: "Bøye ble lagret", status: "success", duration: 5000, isClosable: true });
            } else {
                setBuoys([...buoys, data]);
                toast({ title: "Bøye ble opprettet", status: "success", duration: 5000, isClosable: true });
            }
            setBuoyData(null);
        }
    };

    const handleDelete = async (id: number) => {
        const { error } = await supabase.from("buoys").delete().eq("id", id);

        if (error) {
            toast({ title: "Kunne ikke slette bøye", status: "error", duration: 5000, isClosable: true });
            return;
        }

        setBuoys(buoys.filter((buoy) => buoy.id !== id));
        toast({ title: "Bøye ble slettet", status: "success", duration: 5000, isClosable: true });
    };

    const handleEdit = (buoy: BuoyRow) => {
        setBuoyData(buoy);
    };

    const handleChangeBuoyData = (e: ChangeEvent<HTMLInputElement>) => {
        if (buoyData === null) return;
        setBuoyData({ ...buoyData, [e.target.name]: e.target.value });
    };

    return (
        <Stack spacing={2}>
            <Heading>Rediger bøyer</Heading>
            <BuoyTable buoys={buoys} onDelete={handleDelete} onEdit={handleEdit} />
            {buoyData === null && <Button onClick={() => setBuoyData({} as BuoyInsert)}>Legg til bøye</Button>}
            {buoyData !== null && (
                <>
                    <Heading size={"md"}>{buoyData && buoyData.id ? "Rediger bøye" : "Legg til bøye"}</Heading>
                    <HStack justifyContent={"space-between"}>
                        <InputWithLabel
                            name="name"
                            label="Navn"
                            value={buoyData?.name ?? ""}
                            onChange={handleChangeBuoyData}
                        />
                        <InputWithLabel
                            name="comment"
                            label="Kommentar"
                            value={buoyData?.comment ?? ""}
                            onChange={handleChangeBuoyData}
                        />
                        <FormControl display="flex" alignItems="center" pt={5}>
                            <FormLabel htmlFor="email-alerts" mb="0">
                                Inkluder i initiell kartvisning
                            </FormLabel>
                            <Switch
                                name="affect_viewport"
                                isChecked={buoyData.affect_viewport ?? true}
                                onChange={(e) => setBuoyData({ ...buoyData, affect_viewport: e.target.checked })}
                            />
                        </FormControl>
                        <HStack>
                            <InputWithLabel
                                name="lat"
                                label="Breddegrad"
                                value={buoyData?.lat ?? ""}
                                onChange={handleChangeBuoyData}
                            />
                            <InputWithLabel
                                name="long"
                                label="Lengdegrad"
                                value={buoyData?.long ?? ""}
                                onChange={handleChangeBuoyData}
                            />
                        </HStack>
                    </HStack>
                    <MapContainer
                        center={[58.86043579690258, 9.932234796049398]}
                        zoom={7}
                        scrollWheelZoom={false}
                        style={{ height: "400px" }}
                    >
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <LocationMarker />
                    </MapContainer>
                    <Button colorScheme={"green"} onClick={handleSubmit}>
                        Lagre bøye
                    </Button>
                    <Button onClick={() => setBuoyData(null)}>Avbryt</Button>
                </>
            )}
            {buoyData === null && <BuoyMap buoys={buoys} />}
        </Stack>
    );
};

export default EditBuoys;
